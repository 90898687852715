import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import B2App from './B2App';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  b2State: B2App,
});
