import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignUp,
    userTwitterSignIn
} from 'actions/B2Auth';

import {
    RESET_PASSWORD_REQUEST,
    CLOSE_B2_MESSAGE
} from 'constants/B2ActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';

class ForgotPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            username:''
        }
    }

    forgotPassword = () => {
        const b2SecEvent = {
            type: RESET_PASSWORD_REQUEST,
            eventDetails: {
                username: this.state.username
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    closeB2Message = () => {
        const b2SecEvent = {
            type: CLOSE_B2_MESSAGE,
            eventDetails: {}
        }
        this.props.processB2SecurityEvent(b2SecEvent);
    }

    componentDidUpdate() {
        if (this.props.b2Message) {
            setTimeout(() => {
                this.closeB2Message();
            }, 100);
        }
    }

    render() {
        const {
            username
        } = this.state;

        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Safee">
                            <img src={require("assets/images/safee_logo_square.png")} /*style={{"width":"200px", "height":"145px"}}*/ alt="Safee" title="Safee"/>
                        </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header">
                            <h1>Forgot Password</h1>
                        </div>



                        <div className="app-login-form">
                            <form method="post" action="/">
                                <fieldset>
                                    <TextField
                                        type="text"
                                        label="Email"
                                        onChange={(event) => this.setState({username: event.target.value})}
                                        fullWidth
                                        defaultValue={username}
                                        margin="normal"
                                        className="mt-0 mb-2"
                                    />

                                    {/* <TextField
                                      type="text"
                                      label="Name"
                                      onChange={(event) => this.setState({name: event.target.value})}
                                      fullWidth
                                      defaultValue={name}
                                      margin="normal"
                                      className="mt-0 mb-2"
                                    /> */}
                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button onClick={() => {
                                            this.forgotPassword();
                                        }
                                        } variant="contained" color="primary">
                                            <IntlMessages id="appModule.resetPassword"/>
                                        </Button>
                                        <Link to="/signin">
                                            <IntlMessages id="appModule.signin"/>
                                        </Link>
                                    </div>
                                    <div className="app-social-block my-1 my-sm-3">
                                        <IntlMessages id="signIn.connectWith"/>
                                        <ul className="social-link">
                                            <li>
                                                <IconButton className="icon"
                                                    onClick={() => {
                                                        window.location.path = 'https://www.linkedin.com/company/safeeau';
                                                    }}>
                                                    <i className="zmdi zmdi-facebook"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <Link to={{ pathname: "https://www.linkedin.com/company/safeeau" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            window.location.path = 'https://www.linkedin.com/company/safeeau';
                                                        }}>
                                                        <i className="zmdi zmdi-linkedin"/>
                                                    </IconButton>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to={{ pathname: "https://twitter.com/SafeeAU" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            this.props.showAuthLoader();
                                                            this.props.userTwitterSignIn();
                                                        }}>
                                                        <i className="zmdi zmdi-twitter"/>
                                                    </IconButton>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to={{ pathname: "https://www.youtube.com/channel/UCNtiUCCHl86v2eERA7Nvgmw" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            this.props.showAuthLoader();
                                                            this.props.userGoogleSignIn();
                                                        }}>
                                                        <i className="zmdi zmdi-youtube-play"/>
                                                    </IconButton>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.props.loginLoader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {this.props.b2Message && this.props.b2Message.type==='info' && NotificationManager.info(this.props.b2Message.message)}
                {this.props.b2Message && this.props.b2Message.type==='error' && NotificationManager.error(this.props.b2Message.message)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = ({b2State}) => {
    const {loginLoader, b2Message} = b2State;
    return {loginLoader, b2Message}
};

export default connect(mapStateToProps, {
    processB2SecurityEvent
})(ForgotPassword);
