import React from 'react';
import TextField from '@material-ui/core/TextField';
import {ON_RECORD_FIELD_VALUE_CHANGED, ON_RECORD_ATTRIBUTE_UPDATED, ON_LOOKUP_OPENED, ON_QBE_SEARCH_PERFORMED, ON_RECORD_SELECTED} from 'constants/B2ActionTypes';
import {getOriginalSelectedRecord, getOriginalRecordById} from 'util/B2Utils';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import B2Dialog from '../../B2/B2Dialog';
import {DatePicker, DateTimePicker} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
//dayjs.tz.setDefault('Australia/Adelaide');

class SafeeTZDateTextField extends React.Component {
    qbeTextFieldKeyPress = event => {
        if(event.charCode === 13){
            event.preventDefault(); // Ensure it is only this code that runs

            if (event.target.value && event.target.value.includes("\\")){
                return;
            }

            let filterRecord = this.props.datasource.entityList.phantomEntity;

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                return;
            }

            const b2Event = {
                type: ON_QBE_SEARCH_PERFORMED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    value: event.target.value
                }
            }

            this.props.processB2Event(b2Event);
        }
    }

    lookupOpened = (event,isFilterLookup) => {
        event.stopPropagation();

        if (this.isDisabled()){
            return;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return;
        }

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return;
            }
            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (this.props.rowDatasource){
            const b2Event = {
                type: ON_LOOKUP_OPENED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    recordId: this.props.rowDatasource.attributes.id.value,
                    isFilterLookup: isFilterLookup,
                    lookupDialog: this.props.uiDefinition.lookupDialog
                }
            }

            this.props.processB2Event(b2Event);
        } else if (this.props.datasource && this.props.datasource.entityList &&
                    (this.props.datasource.entityList.selectedRecord || isFilterLookup)){
            const b2Event = {
                type: ON_LOOKUP_OPENED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    isFilterLookup: isFilterLookup,
                    lookupDialog: this.props.uiDefinition.lookupDialog
                }
            }

            this.props.processB2Event(b2Event);
        }
    };

    fieldClicked = event => {
        event.stopPropagation();

        if (this.props.rowDatasource && this.props.rowDatasource.attributes.id.value === this.props.datasource.entityList.selectedRecord.attributes.id.value){
            return;
        }

        const b2Event = {
            type: ON_RECORD_SELECTED,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: this.props.rowDatasource.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    fieldChanged = event => {
        event.stopPropagation();

        if (event.target.value && event.target.value.includes("\\")){
            return;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return;
        }

        if (this.props.rowDatasource && targetAttribute.includes(".")){
            return;
        }

        if (targetAttribute.includes(".")){
            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        const b2Event = {
            type: ON_RECORD_FIELD_VALUE_CHANGED,
            eventDetails: {
                datasource: this.props.datasource,
                attribute: targetAttribute,
                isPhantom: false,
                value: event.target.value
            }
        }

        this.props.processB2Event(b2Event);
    };

    filterFieldChanged = event => {
        event.stopPropagation();

        if (event.target.value && event.target.value.includes("\\")){
            return;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return;
        }

        if (targetAttribute.includes(".")){
            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        const b2Event = {
            type: ON_RECORD_FIELD_VALUE_CHANGED,
            eventDetails: {
                datasource: this.props.datasource,
                attribute: targetAttribute,
                isPhantom: true,
                value: event.target.value
            }
        }

        this.props.processB2Event(b2Event);
    };

    dateFieldUpdated = event => {
        if (event != null && event.format().includes("\\")){
            return;
        }
        if (this.props.rowDatasource){
            let record = getOriginalRecordById(this.props.datasource.id, this.props.rowDatasource.attributes.id.value);

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                return;
            }

            if (event != null){
                if (record.attributes[targetAttribute].value === event.format()){
                    return;
                }
            }


            if (
                !(
                    record.attributes[targetAttribute].value === null &&
                    event === null
                ) &&
                !(
                    record.attributes[targetAttribute].value === '' &&
                    event === null
                )
            ){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        recordId: this.props.rowDatasource.attributes.id.value,
                        attribute: targetAttribute,
                        isPhantom: false,
                        value: (event==null)?null:event.format()
                    }
                }

                this.props.processB2Event(b2Event);
            }
        } else {
            let originalSelectedRecord = getOriginalSelectedRecord(this.props.datasource.id);

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }

            if (event != null){
                if (originalSelectedRecord.attributes[targetAttribute].value === event.format()){
                    return;
                }
            }

            if (
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === null &&
                    event === null
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === '' &&
                    event === null
                )
            ){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        attribute: targetAttribute,
                        isPhantom: false,
                        value: (event==null)?null:event.format()
                    }
                }

                this.props.processB2Event(b2Event);
            }
        }
    };

    fieldUpdated = event => {
        event.stopPropagation();

        if (this.props.rowDatasource){
            let record = getOriginalRecordById(this.props.datasource.id, this.props.rowDatasource.attributes.id.value);

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                return;
            }

            if (record.attributes[targetAttribute].value === event.target.value){
                return;
            }

            if (
                !(
                    record.attributes[targetAttribute].value === null &&
                    event.target.value === null
                ) &&
                !(
                    record.attributes[targetAttribute].value === '' &&
                    event.target.value === null
                ) &&
                !(
                    record.attributes[targetAttribute].value === null &&
                    event.target.value === ''
                ) &&
                !(
                    record.attributes[targetAttribute].value === '' &&
                    event.target.value === ''
                )
            ){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        recordId: this.props.rowDatasource.attributes.id.value,
                        attribute: targetAttribute,
                        isPhantom: false,
                        value: event.target.value
                    }
                }

                this.props.processB2Event(b2Event);
            }
        } else {
            let originalSelectedRecord = getOriginalSelectedRecord(this.props.datasource.id);

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }

            if (originalSelectedRecord.attributes[targetAttribute].value === event.target.value){
                return;
            }

            if (
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === null &&
                    event.target.value === null
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === '' &&
                    event.target.value === null
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === null &&
                    event.target.value === ''
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === '' &&
                    event.target.value === ''
                )
            ){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        attribute: targetAttribute,
                        isPhantom: false,
                        value: event.target.value
                    }
                }

                this.props.processB2Event(b2Event);
            }
        }
    };

    dateFilterFieldUpdated = event => {
        let targetAttribute = this.props.uiDefinition.attribute;

        if (event != null){
            let value = event.format();

            if (value && value.includes("\\")){
                return;
            }

            let filterRecord = this.props.datasource.entityList.phantomEntity;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                return;
            }

            const b2Event = {
                type: ON_QBE_SEARCH_PERFORMED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    value: value
                }
            }

            this.props.processB2Event(b2Event);
        }else{
            const b2Event = {
                type: ON_QBE_SEARCH_PERFORMED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    value: null
                }
            }

            this.props.processB2Event(b2Event);
        }
    };


    filterFieldUpdated = event => {
        event.stopPropagation();

        if (event.target.value && event.target.value.includes("\\")){
            return;
        }

        let filterRecord = this.props.datasource.entityList.phantomEntity;

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return;
        }

        if (targetAttribute.includes(".")){
            return;
        }

        const b2Event = {
            type: ON_RECORD_ATTRIBUTE_UPDATED,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id,
                attribute: targetAttribute,
                isPhantom: true,
                value: event.target.value
            }
        }

        this.props.processB2Event(b2Event);
    };

    getLabel(){
        if (this.props.uiDefinition.label){
            return this.props.uiDefinition.label;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return;
        }

        if (targetAttribute.includes(".")){
            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (this.props.datasource.entityList && this.props.datasource.entityList.meta && typeof (this.props.datasource.entityList.meta.attributes[targetAttribute])!='undefined' && this.props.datasource.entityList.meta.attributes[targetAttribute].title){
            return this.props.datasource.entityList.meta.attributes[targetAttribute].title;
        } else {
            return targetAttribute;
        }
    }

    isReadOnly(){
        if (
                (
                    this.props.uiDefinition.readOnly &&
                    this.props.uiDefinition.readOnly==="true"
                ) ||
                (
                    this.props.uiDefinition.readonly &&
                    this.props.uiDefinition.readonly==="true"
                ) ||
                this.props.readOnlyTable
        ){
            return true;
        }

        if (this.props.rowDatasource){
            if (this.props.rowDatasource.isReadOnly && this.props.rowDatasource.isReadOnly==="true"){
                return true;
            }

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return false;
            }

            if (targetAttribute.includes(".")){
                return true;
            }

            if (!this.props.rowDatasource.attributes[targetAttribute]){
                return true;
            }

            if (this.props.rowDatasource.attributes[targetAttribute].isReadOnly &&
                this.props.rowDatasource.attributes[targetAttribute].isReadOnly === "true"){
                return true;
            }

            return false;
        } else {
            if (!this.props.datasource.entityList.selectedRecord){
                return true;
            }

            if (this.props.datasource.entityList.selectedRecord.isReadOnly && this.props.datasource.entityList.selectedRecord.isReadOnly==="true"){
                return true;
            }

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return false;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }

            if (!this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]){
                return true;
            }

            if (this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].isReadOnly &&
                this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].isReadOnly === "true"){
                return true;
            }

            return false;
        }
    }

    getDataType(){

        if (!this.props.datasource.entityList){
            return "";
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return "";
        }

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return;
            }

            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (this.props.datasource.entityList.meta.attributes[targetAttribute]){
            return this.props.datasource.entityList.meta.attributes[targetAttribute].type;
        }

//        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]) === 'undefined' ||
//            !this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] ||
//            this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] === null
//        ){
//            return "";
//        }
//
//        let dataType = null;
//        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
//            dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;
//            // TODO: set dataType
//        }else{
//            dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;
//        }

        return null;
    }


    getValue(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return "";
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return "";
        }

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return;
            }

            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] ||
            this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] === null
        ){
            return "";
        }

        let value = null;

        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
            value = this.props.rowDatasource.attributes[targetAttribute].value;
        }else{
            value = this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].value;
        }

        if (value !== undefined && value !== null && value.constructor == Object){
            return JSON.stringify(value, null, 2);
        }

        let dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;

        if (dataType && (dataType==='DATE' || dataType==='DATETIME') && value && ''!==value){
            if (this.props.uiDefinition.format){
                return moment(value).format(this.props.uiDefinition.format);
            }
        }

        return value;
    }

    getAttributeValue(attribute){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return "";
        }

        let targetAttribute = attribute;

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return;
            }

            targetAttribute = attribute.substring(attribute.lastIndexOf(".")+1, attribute.length);
        }

        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] ||
            this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] === null
        ){
            return "";
        }

        let value = null;

        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
            value = this.props.rowDatasource.attributes[targetAttribute].value;
        }else{
            value = this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].value;
        }

        if (value !== undefined && value !== null && value.constructor == Object){
            return JSON.stringify(value, null, 2);
        }

        let dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;

        if (dataType && (dataType==='DATE' || dataType==='DATETIME') && value && ''!==value){
            if (this.props.uiDefinition.format){
                return moment(value).format(this.props.uiDefinition.format);
            }
        }

        return value;
    }

    getFilterValue(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.phantomEntity){
            return "";
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return "";
        }

        if (targetAttribute.includes(".")){
            return null;
        }

        if (typeof(this.props.datasource.entityList.phantomEntity.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.phantomEntity.attributes[targetAttribute] ||
            this.props.datasource.entityList.phantomEntity.attributes[targetAttribute] === null
        ){
            return "";
        }

//        let dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;

//        if (dataType && (dataType==='DATE' || dataType==='DATETIME') && this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value && ''!==this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value){
//            //if (this.props.uiDefinition.format){
//                return moment(this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value).format(this.props.uiDefinition.format);
//            //}
//        }

        return this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value;
    }

    isRequired(){
        if (typeof(this.props.uiDefinition.required) !== 'undefined' && this.props.uiDefinition.required === 'true'){
            return true;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return false;
        }

        if (targetAttribute.includes(".")){
            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return false;
        }

        if (typeof(this.props.datasource.entityList.meta.attributes[targetAttribute]) !== 'undefined' &&
            typeof(this.props.datasource.entityList.meta.attributes[targetAttribute].isRequired) !== 'undefined' &&
            this.props.datasource.entityList.meta.attributes[targetAttribute].isRequired === 'true'
        ){
            return true;
        }

        return false;
    }

    isDisabled(){
        if (this.props.isFilter){
            return false;
        }

        if (this.props.rowDatasource){
            return false;
        }

        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return true;
        }

        return false;
    }

    isFilterDisabled(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.phantomEntity){
            return true;
        }

        return false;
    }

    generateDateField(){
        let format = this.props.uiDefinition.format;
        if (!format){
            format = "YYYY-MM-DD";
        }
        if (this.props.toggleTableFilter && this.props.isFilter){
            const value = this.getFilterValue();
            const disabled = this.isFilterDisabled();
            const {datasource, bean, uiDefinition, parentUiDefinition, isFilter, isHeader, toggleTableFilter, ...newProps} = this.props;

            return <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          animateYearScrolling={false}
                          leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                          rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                          InputProps={{
                            readOnly: "true",
                            endAdornment:  <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    <i className="zmdi zmdi-calendar" title="Lookup"/>
                                  </IconButton>
                                </InputAdornment>,

                            startAdornment: <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="start"
                                    >
                                        <i className="zmdi zmdi-filter-list" title="Filter"/>
                                    </IconButton>
                                </InputAdornment> ,
                            autoComplete:'off',
                            form: {
                              autocomplete: 'off',
                            }
                            }}
                            key={this.props.uiDefinition.id+"PhantomTextField"}
                            sx={
                                    {
                                        "margin":"2px 2px 2px 0px",
                                        "backgroundColor":"#fcfceb"
                                    }
                            }
                            autoOk={true}
                            disabled={disabled}
                            slotProps={{
                                actionBar: {
                                  actions: ['clear', 'cancel', 'accept'],
                                },

                                textField: { size: 'small', variant: 'outlined', shouldRespectLeadingZeros: true, readOnly: true }
                            }}
                            value={(!value)?null:dayjs(value)}
                            onChange={(e) => this.dateFilterFieldUpdated(e)}
                            //onBlur={(e) => this.dateFilterFieldUpdated(e)}
                            format={format}
                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                        />
                    </LocalizationProvider>
                </React.Fragment>
            } else if (this.props.rowDatasource) {
                const value = this.getValue();
                const readOnly = this.isReadOnly();
                const disabled = this.isDisabled();
                const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = this.props;

                let style= {
                       "margin":"5px 2px",
                       "padding":"0px 5px 0px 5px"
                   };

                if (readOnly || disabled){
                    style.backgroundColor = "#ccc8c8";
                } else {
                    style.backgroundColor = "#fcfceb";
                }

                return <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                 animateYearScrolling={false}
                                 leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                 rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                 InputProps={{
                                   disableUnderline: true,
                                   readOnly: readOnly,
                                   endAdornment:  <InputAdornment position="end">
                                         <IconButton
                                           aria-label="toggle password visibility"
                                           edge="end"
                                         >
                                           <i className="zmdi zmdi-calendar" title="Lookup"/>
                                         </IconButton>
                                       </InputAdornment>,

                                   startAdornment: <InputAdornment position="end">{this.props.uiDefinition.endAdornment}</InputAdornment> ,
                                   autoComplete:'off',
                                   form: {
                                     autocomplete: 'off',
                                   }
                                   }}
                                   autoOk={true}
                                   disabled={disabled}
                                   readOnly={readOnly}
                                   key={this.props.uiDefinition.id+"_"+this.props.rowDatasource.attributes.id.value}
                                   sx={style}
                                   value={(!value)?null:dayjs(value)}
                                   slotProps={{
                                        actionBar: {
                                          actions: ['clear', 'cancel', 'accept'],
                                        },
                                        textField: {
                                            size: 'small', margin: 'normal', variant: 'outlined', shouldRespectLeadingZeros: true, readOnly: true
                                        }
                                    }}
                                   inputVariant="outlined"
                                   onAccept={(e) => this.dateFieldUpdated(e)}
                                   //onBlur={(e) => this.dateFieldUpdated(e)}
                                   format={format}
                                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                               />
                           </LocalizationProvider>
                       </React.Fragment>
            } else {

                const value = this.getValue();

                const readOnly = this.isReadOnly();
                const disabled = this.isDisabled();
                let required = false;
                if (!disabled && !readOnly){
                    required = this.isRequired();
                }
                const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = this.props;

                return <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                timezone={undefined}
                                 animateYearScrolling={false}
                                 leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                 rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                 InputProps={{
                                   readOnly: readOnly,
                                   endAdornment:  <InputAdornment position="end">
                                         <IconButton
                                           aria-label="toggle password visibility"
                                           edge="end"
                                         >
                                           <i className="zmdi zmdi-calendar" title="Lookup"/>
                                         </IconButton>
                                       </InputAdornment>,

                                   startAdornment: (this.props.uiDefinition.startAdornment) && (
                                                   <InputAdornment position="start">{this.props.uiDefinition.startAdornment}</InputAdornment>
                                                 ),
                                   autoComplete:'off',
                                   form: {
                                     autocomplete: 'off',
                                   }
                                   }}
                                   autoOk={true}
                                   required={required}
                                   disabled={disabled}
                                   readOnly={readOnly}
                                   key={this.props.uiDefinition.id}
                                   id={this.props.uiDefinition.id}
                                   label={this.getLabel()}
                                   value={(!value)?null:dayjs(value)}
                                   onAccept={(e) => this.dateFieldUpdated(e)}
                                   //onBlur={(e) => this.dateFieldUpdated(e)}
                                   slotProps={{
                                        actionBar: {
                                          actions: ['clear', 'cancel', 'accept'],
                                        },
                                        textField: { size: 'small', margin: 'dense', variant: 'outlined', fullWidth: true, shouldRespectLeadingZeros: true, readOnly: true }

                                    }}
                                   placeholder={this.props.uiDefinition.placeholder}
                                   helperText={this.props.uiDefinition.helperText}
                                   format={format}
                                   timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                               />
                           </LocalizationProvider>
                       </React.Fragment>
            }
    }

    generateDateTimeField(){

        if (this.props.toggleTableFilter && this.props.isFilter){
            let filterFormat = this.props.uiDefinition.filterFormat;

            if (!filterFormat){
                filterFormat = "YYYY-MM-DD hh:mm A";
            }
            const value = this.getFilterValue();

            const disabled = this.isFilterDisabled();
            const {datasource, bean, uiDefinition, parentUiDefinition, isFilter, isHeader, toggleTableFilter, ...newProps} = this.props;

            if (filterFormat.includes("hh") || filterFormat.includes("mm") || filterFormat.includes("A") || filterFormat.includes("a")){
                return <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  animateYearScrolling={false}
                                  leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                  InputProps={{
                                    readOnly: "true",
                                    endAdornment:  <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                          >
                                            <i className="zmdi zmdi-calendar" title="Lookup"/>
                                          </IconButton>
                                        </InputAdornment>,

                                    startAdornment: <InputAdornment position="start">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="start"
                                            >
                                                <i className="zmdi zmdi-filter-list" title="Filter"/>
                                            </IconButton>
                                        </InputAdornment> ,
                                    autoComplete:'off',
                                    form: {
                                      autocomplete: 'off',
                                    }
                                    }}
                                    key={this.props.uiDefinition.id+"PhantomTextField"}
                                    sx={
                                            {
                                                "margin":"2px 2px 2px 0px",
                                                "backgroundColor":"#fcfceb"
                                            }
                                    }
                                    autoOk={true}
                                    disabled={disabled}
                                    slotProps={{
                                        actionBar: {
                                          actions: ['clear', 'cancel', 'accept'],
                                        },
                                        textField: { size: 'small', variant: 'outlined', shouldRespectLeadingZeros: true, readOnly: true }
                                    }}
                                    value={(!value)?null:dayjs(value)}
                                    onAccept={(e) => this.dateFilterFieldUpdated(e)}
                                    //onBlur={(e) => this.dateFilterFieldUpdated(e)}
                                    format={filterFormat}
                                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                />
                            </LocalizationProvider>
                        </React.Fragment>
                }else{
                    return <React.Fragment>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      animateYearScrolling={false}
                                      leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                      InputProps={{
                                        readOnly: "true",
                                        endAdornment:  <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                              >
                                                <i className="zmdi zmdi-calendar" title="Lookup"/>
                                              </IconButton>
                                            </InputAdornment>,

                                        startAdornment: <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="start"
                                                >
                                                    <i className="zmdi zmdi-filter-list" title="Filter"/>
                                                </IconButton>
                                            </InputAdornment> ,
                                        autoComplete:'off',
                                        form: {
                                          autocomplete: 'off',
                                        }
                                        }}
                                        key={this.props.uiDefinition.id+"PhantomTextField"}
                                        sx={
                                                {
                                                    "margin":"2px 2px 2px 0px",
                                                    "backgroundColor":"#fcfceb"
                                                }
                                        }
                                        autoOk={true}
                                        disabled={disabled}
                                        slotProps={{
                                            actionBar: {
                                              actions: ['clear', 'cancel', 'accept'],
                                            },
                                            textField: { size: 'small', variant: 'outlined', shouldRespectLeadingZeros: true, readOnly: true }
                                        }}
                                        value={(!value)?null:dayjs(value)}
                                        onChange={(e) => this.dateFilterFieldUpdated(e)}
                                        //onBlur={(e) => this.dateFilterFieldUpdated(e)}
                                        format={filterFormat}
                                        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                                    />
                                </LocalizationProvider>
                            </React.Fragment>
                }

            } else if (this.props.rowDatasource) {

                let timezoneAttVal = this.getTimezone();
                let realTimezone = this.getRealTimezone();

                let format = this.props.uiDefinition.format;

                if (!format){
                    format = 'YYYY-MM-DD hh:mm A' + ((timezoneAttVal != null && timezoneAttVal.length > 0)?(" ["+timezoneAttVal+"]"):"");
                }

                const value = this.getValue();
                const readOnly = this.isReadOnly();
                const disabled = this.isDisabled();
                const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = this.props;

                let style= {
                       "margin":"5px 2px",
                       "padding":"0px 5px 0px 5px"
                   };

                if (readOnly || disabled){
                    style.backgroundColor = "#ccc8c8";
                } else {
                    style.backgroundColor = "#fcfceb";
                }

                return <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DateTimePicker
                                timezone={realTimezone}
                                 animateYearScrolling={false}
                                 leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                 rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                 InputProps={{
                                   disableUnderline: true,
                                   readOnly: readOnly,
                                   endAdornment:  <InputAdornment position="end">
                                         <IconButton
                                           aria-label="toggle password visibility"
                                           edge="end"
                                         >
                                           <i className="zmdi zmdi-calendar" title="Lookup"/>
                                         </IconButton>
                                       </InputAdornment>,

                                   startAdornment: <InputAdornment position="end">{this.props.uiDefinition.endAdornment}</InputAdornment> ,
                                   autoComplete:'off',
                                   form: {
                                     autocomplete: 'off',
                                   }
                                   }}
                                   autoOk={true}
                                   disabled={disabled}
                                   readOnly={readOnly}
                                   key={this.props.uiDefinition.id+"_"+this.props.rowDatasource.attributes.id.value}
                                   style={style}
                                   value={(!value)?null:dayjs(value)}
                                   slotProps={{
                                        actionBar: {
                                          actions: ['clear', 'cancel', 'accept'],
                                        },
                                        textField: { size: 'small', margin: 'normal', variant: 'outlined', shouldRespectLeadingZeros: true, readOnly: true }
                                    }}
                                   inputVariant="outlined"
                                   onAccept={(e) => this.dateFieldUpdated(e)}
                                   //onBlur={(e) => this.dateFieldUpdated(e)}
                                   format={format}
                                    timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                               />
                           </LocalizationProvider>
                       </React.Fragment>
            } else {
                let timezoneAttVal = this.getTimezone();
                let realTimezone = this.getRealTimezone();

                const value = this.getValue();

                let format = this.props.uiDefinition.format;

                if (!format){
                    format = 'YYYY-MM-DD hh:mm A' + ((timezoneAttVal != null && timezoneAttVal.length > 0)?(" ["+timezoneAttVal+"]"):"");
                }

                const readOnly = this.isReadOnly();
                const disabled = this.isDisabled();
                let required = false;
                if (!disabled && !readOnly){
                    required = this.isRequired();
                }
                const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = this.props;

                return <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DateTimePicker
                                timezone={realTimezone}
                                 animateYearScrolling={false}
                                 leftArrowIcon={<i className="zmdi zmdi-arrow-back"/>}
                                 rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
                                 InputProps={{
                                   readOnly: readOnly,
                                   endAdornment:  <InputAdornment position="end">
                                         <IconButton
                                           aria-label="toggle password visibility"
                                           edge="end"
                                         >
                                           <i className="zmdi zmdi-calendar" title="Lookup"/>
                                         </IconButton>
                                       </InputAdornment>,

                                   startAdornment: (this.props.uiDefinition.startAdornment) && (
                                                   <InputAdornment position="start">{this.props.uiDefinition.startAdornment}</InputAdornment>
                                                 ),
                                   autoComplete:'off',
                                   form: {
                                     autocomplete: 'off',
                                   }
                                   }}
                                   autoOk={true}
                                   required={required}
                                   disabled={disabled}
                                   readOnly={readOnly}
                                   key={this.props.uiDefinition.id}
                                   id={this.props.uiDefinition.id}
                                   label={this.getLabel()}
                                   value={(!value)?null:dayjs(value)}
                                   onAccept={(e) => this.dateFieldUpdated(e)}
                                   //onBlur={(e) => this.dateFieldUpdated(e)}
                                   slotProps={{
                                        actionBar: {
                                          actions: ['clear', 'cancel', 'accept'],
                                        },
                                        textField: { size: 'small', margin: 'dense', variant: 'outlined', fullWidth: true, shouldRespectLeadingZeros: true, readOnly: true }
                                    }}
                                   placeholder={this.props.uiDefinition.placeholder}
                                   helperText={this.props.uiDefinition.helperText}
                                   format={format}
                                   timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                               />
                           </LocalizationProvider>
                       </React.Fragment>
            }
    }

    getTimezone(){

        let timezone = this.getAttributeValue(this.props.uiDefinition.timezoneAttribute);

        if (timezone){
            return timezone;
        }

        let timeOffsetAttribute = this.getAttributeValue(this.props.uiDefinition.timeOffsetAttribute);

        if (!timeOffsetAttribute){
            return "";
        }

        let value = this.getValue();

        let datetime = moment(value);

        let bestTZ = this.getBestTZ(datetime, timeOffsetAttribute);

        if (bestTZ === "Australia/Melbourne"){
            return "VIC";
        }else if (bestTZ === "Australia/Sydney"){
            return "NSW";
        }else if (bestTZ === "Australia/Canberra"){
            return "ACT";
        }else if (bestTZ === "Australia/Brisbane"){
            return "QLD";
        }else if (bestTZ === "Australia/Hobart"){
            return "TAS";
        }else if (bestTZ === "Australia/Darwin"){
            return "NT";
        }else if (bestTZ === "Australia/Adelaide"){
            return "SA";
        }else if (bestTZ === "Australia/Perth"){
            return "WA";
        }

        return "";
    }

    getRealTimezone(){
        let timezone = this.getAttributeValue(this.props.uiDefinition.timezoneAttribute);

        if (timezone){
            let timezoneStr = this.getTimezoneStr(timezone);
            if (timezoneStr){
                return timezoneStr;
            }else{
                return undefined;
            }
        }

        let timeOffsetAttribute = this.getAttributeValue(this.props.uiDefinition.timeOffsetAttribute);

        if (!timeOffsetAttribute){
            return undefined;
        }

        let value = this.getValue();

        let datetime = moment(value);

        return this.getBestTZ(datetime, timeOffsetAttribute);
    }

    getTimezoneStr(timezone){
        if (timezone === 'ACT'){
            return "Australia/Canberra";
        }else if (timezone === 'NSW'){
            return "Australia/Sydney";
        }else if (timezone === 'NT'){
            return "Australia/Darwin";
        }else if (timezone === 'QLD'){
            return "Australia/Brisbane";
        }else if (timezone === 'SA'){
            return "Australia/Adelaide";
        }else if (timezone === 'TAS'){
            return "Australia/Hobart";
        }else if (timezone === 'VIC'){
            return "Australia/Melbourne";
        }else if (timezone === 'WA'){
            return "Australia/Perth";
        }
        return null;
    }

    getBestTZ(dateTime, offset) {
        let possibleTZs = this.getPossibleTimezones(offset);

        if (possibleTZs.length === 1){
            return moment(dateTime.valueOf()).tz(possibleTZs[0]);
        }

        let offsetDuration = this.getOffsetDuration(offset);

        let adjustedDateTime = dateTime.clone().utc().add(offsetDuration, "minutes");

        let hour = parseInt(adjustedDateTime.format("HH"));

        for (let timezoneIndex in possibleTZs){
          let tzDateTime = moment(dateTime).tz(possibleTZs[timezoneIndex]);
          if (parseInt(tzDateTime.format("HH")) === hour){
            return possibleTZs[timezoneIndex];
          }
        }

        return undefined;
    }

    getOffsetDuration(duration){
        if (duration.length < 5 || duration.length > 6){
          return 0;
        }

        if (duration.startsWith("+")){
          return ((parseInt(duration.substring(1,3)) * 60) + parseInt(duration.substring(4,6)));
        }else if (duration.startsWith("-")){
          return -1 * (((parseInt(duration.substring(1,3)) * 60) + parseInt(duration.substring(4,6))));
        }else{
          return ((parseInt(duration.substring(0,2)) * 60) + parseInt(duration.substring(3,5)));
        }
    }

    getPossibleTimezones(offset){
        if (offset === "+08:00"){
            return ['Australia/Perth'];
        }

        if (offset === "+10:30"){
            return ['Australia/Adelaide'];
        }

        if (offset === "+09:30"){
            return ['Australia/Adelaide', 'Australia/Darwin'];
        }

        if (offset === "+10:00"){
            return ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Brisbane', 'Australia/Canberra', 'Australia/Hobart'];
        }

        if (offset === "+11:00"){
            return ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Canberra', 'Australia/Hobart'];
        }

        return ['Australia/Melbourne']; // default. System to adjust calculations
    }


    render() {

        const dataType = this.getDataType();

        if ('DATE'===dataType){
            return (this.generateDateField());
        }

        else if ('DATETIME'===dataType){
            return (this.generateDateTimeField());
        }

        if (this.props.toggleTableFilter && this.props.isFilter){
            const value = this.getFilterValue();
            const disabled = this.isFilterDisabled();
            const {datasource, bean, uiDefinition, parentUiDefinition, isFilter, isHeader, toggleTableFilter, ...newProps} = this.props;

            return <React.Fragment>
                        <TextField
                            InputProps={{
                                endAdornment: (this.props.uiDefinition.lookupDialog)? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) => this.lookupOpened(e,true)}
                                        edge="end"
                                      >
                                        <i className="zmdi zmdi-zoom-in" title="Lookup"/>
                                      </IconButton>
                                    </InputAdornment>
                                  ):(
                                    (this.props.uiDefinition.endAdornment) && (
                                        <InputAdornment position="end">{this.props.uiDefinition.endAdornment}</InputAdornment>
                                      )
                                  ),
                                startAdornment: <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        edge="start"
                                                    >
                                                        <i className="zmdi zmdi-filter-list" title="Filter"/>
                                                    </IconButton>
                                    </InputAdornment> ,
                                autoComplete:'off',
                                form: {
                                    autocomplete: 'off',
                                }
                            }}
                            key={this.props.uiDefinition.id+"PhantomTextField"}
                            style={
                                    {
                                        "margin":"2px 2px 2px 0px",
                                        "backgroundColor":"#fcfceb"
                                    }
                            }
                            disabled={disabled}
                            size="small"
                            variant={"outlined"}
                            value={(!value)?'':value}
                            onChange={(e) => this.filterFieldChanged(e)}
                            onBlur={(e) => this.filterFieldUpdated(e)}
                            onKeyPress={this.qbeTextFieldKeyPress}
                        />
                        {this.props.uiDefinition.lookupDialog && this.props.beans[this.props.uiDefinition.lookupDialog].isOpen === "true" &&
                            <B2Dialog {...newProps}
                                isLookup="true"
                                isFilterLookup={true}
                                parentUiDefinition={this.props.uiDefinition}
                                uiDefinition={this.props.lookups[this.props.uiDefinition.lookupDialog]}
                                datasource={this.props.datasources[this.props.uiDefinition.lookupDialog]}
                                bean={this.props.beans[this.props.uiDefinition.lookupDialog]}
                            />
                        }
                </React.Fragment>
        } else if (this.props.rowDatasource) {
            const value = this.getValue();
            const readOnly = this.isReadOnly();
            const disabled = this.isDisabled();
            const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = this.props;

            let style= {
                   "margin":"5px 2px",
                   "padding":"0px 5px 0px 5px"
               };

            if (readOnly || disabled){
                style.backgroundColor = "#ccc8c8";
            } else {
                style.backgroundColor = "#fcfceb";
            }
            return <React.Fragment>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                readOnly: readOnly,
                                endAdornment: (this.props.uiDefinition.lookupDialog)? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) => {
                                            if (readOnly || disabled){
                                                return;
                                            } else {
                                                this.lookupOpened(e,false);
                                            }
                                        }}
                                        edge="end"
                                      >
                                        <i className="zmdi zmdi-zoom-in" title="Lookup"/>
                                      </IconButton>
                                    </InputAdornment>
                                  ):(
                                    (this.props.uiDefinition.endAdornment) && (
                                        <InputAdornment position="end">{this.props.uiDefinition.endAdornment}</InputAdornment>
                                      )
                                  ),
                                startAdornment: (this.props.uiDefinition.startAdornment) && (
                                    <InputAdornment position="start">{this.props.uiDefinition.startAdornment}</InputAdornment>
                                  ),
                                autoComplete:'off',
                                form: {
                                    autocomplete: (this.props.uiDefinition.password==='true')?'off':'on',
                                }
                            }}
                            disabled={readOnly || disabled}
                            type={this.props.uiDefinition.password==='true'?'password':undefined}
                            key={this.props.uiDefinition.id+"_"+this.props.rowDatasource.attributes.id.value}
                            style={style}
                            value={(!value)?'':value}
                            onChange={(e) => this.fieldChanged(e)}
                            onClick={(e) => this.fieldClicked(e)}
                            onBlur={(e) => this.fieldUpdated(e)}
                            margin="normal"
                            size="small"
                        />
                            {this.props.uiDefinition.lookupDialog && this.props.beans[this.props.uiDefinition.lookupDialog].isOpen === "true" &&
                                <B2Dialog {...newProps}
                                    isLookup="true"
                                    parentUiDefinition={this.props.uiDefinition}
                                    uiDefinition={this.props.lookups[this.props.uiDefinition.lookupDialog]}
                                    datasource={this.props.datasources[this.props.uiDefinition.lookupDialog]}
                                    bean={this.props.beans[this.props.uiDefinition.lookupDialog]}
                                    />
                            }
                    </React.Fragment>
        } else {
            const value = this.getValue();

            const readOnly = this.isReadOnly();
            const disabled = this.isDisabled();
            let required = false;
            if (!disabled && !readOnly){
                required = this.isRequired();
            }
            const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = this.props;

            return <React.Fragment>
                        <TextField
                            InputProps={{
                                readOnly: readOnly,
                                endAdornment: (this.props.uiDefinition.lookupDialog)? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) => this.lookupOpened(e,false)}
                                        edge="end"
                                      >
                                        <i className="zmdi zmdi-zoom-in" title="Lookup"/>
                                      </IconButton>
                                    </InputAdornment>
                                  ):(
                                    (this.props.uiDefinition.endAdornment) && (
                                        <InputAdornment position="end">{this.props.uiDefinition.endAdornment}</InputAdornment>
                                      )
                                  ),
                                startAdornment: (this.props.uiDefinition.startAdornment) && (
                                    <InputAdornment position="start">{this.props.uiDefinition.startAdornment}</InputAdornment>
                                  ),
                                  autoComplete:'off',
                                  form: {
                                    autocomplete: 'off',
                                  }
                            }}
                            required={required}
                            disabled={disabled}
                            type={this.props.uiDefinition.password==='true'?'password':undefined}
                            key={this.props.uiDefinition.id}
                            id={this.props.uiDefinition.id}
                            label={this.getLabel()}
                            value={(value===null)?'':value}
                            onChange={(e) => this.fieldChanged(e)}
                            onBlur={(e) => this.fieldUpdated(e)}
                            margin="dense"
                            fullWidth
                            size="small"
                            variant={"outlined"}
                            multiline={this.props.uiDefinition.multiline === 'true'}
                            minRows={this.props.uiDefinition.rows}
                            placeholder={this.props.uiDefinition.placeholder}
                            helperText={this.props.uiDefinition.helperText}
                        />
                            {this.props.uiDefinition.lookupDialog && this.props.beans[this.props.uiDefinition.lookupDialog].isOpen === "true" &&
                                <B2Dialog {...newProps}
                                    isLookup="true"
                                    parentUiDefinition={this.props.uiDefinition}
                                    uiDefinition={this.props.lookups[this.props.uiDefinition.lookupDialog]}
                                    datasource={this.props.datasources[this.props.uiDefinition.lookupDialog]}
                                    bean={this.props.beans[this.props.uiDefinition.lookupDialog]}
                                    />
                            }
                    </React.Fragment>
        }
    }
}

export default SafeeTZDateTextField;
