import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import CustomScrollbars from 'util/CustomScrollbars';

class B2Scrollbars extends React.Component {
    render() {
        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = {}
        }

        let height = null;
        if (this.props.uiDefinition.height){
            try{
                height = eval(this.props.uiDefinition.height);
            }catch (err){
                height = this.props.uiDefinition.height;
            }
        }else{
            height = 'calc(100vh - 116px)';
        }

        style.height = height;

        return <CustomScrollbars
                    className="module-list-scroll scrollbar"
                    style={style}
                    key={this.props.uiDefinition.id}
                    id={this.props.uiDefinition.id}>
                    {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                </CustomScrollbars>
    }
}

export default B2Scrollbars;