import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import B2Tab from '../B2Tab';
import {ON_TAB_CHANGED} from 'constants/B2ActionTypes';
import {hasFeatureAccess} from 'util/B2Utils';

class B2Tabs extends React.Component {
    handleTabChange = (event, tabId) => {
        const b2Event = {
            type: ON_TAB_CHANGED,
            eventDetails: {
                beanId: this.props.bean.id,
                tabId: tabId
            }
        }

        this.props.processB2Event(b2Event);
    };

    isFeatureEnabled(uiDefinition){
        if(uiDefinition.feature) {
            let featureDatasource = null;
            if (uiDefinition.featureParentDatasource) {
              const parentDs = uiDefinition.featureParentDatasource;
              if (this.props.beans[parentDs]) {
                const datasourceId = this.props.beans[parentDs].datasource;
                featureDatasource = this.props.datasources[datasourceId];
              }
            } else {
                featureDatasource = this.props.datasource;
            }

            if (!hasFeatureAccess(featureDatasource, uiDefinition.feature)) {
                return false;
            }
        }

        return true;
    }

    generateTabHeaders () {
        if (this.props.uiDefinition.B2Tab){
            if (Array.isArray(this.props.uiDefinition.B2Tab)){
                return this.props.uiDefinition.B2Tab.map(
                    (b2TabDefinition, index) => {
                        if (!this.isFeatureEnabled(b2TabDefinition)){
                            return null;
                        }

                        let style = null;

                        if (b2TabDefinition.style){
                            style = b2TabDefinition.style;
                        }else{
                            style = undefined;
                        }


                        return <Tab key={b2TabDefinition.id} id={b2TabDefinition.id} label={b2TabDefinition.title} value={b2TabDefinition.id} style={style}/>;
                    }
                ).filter((value)=>value!=null)
            }else{
                if (!this.isFeatureEnabled(this.props.uiDefinition)){
                    return;
                }

                let style = null;

                if (this.props.uiDefinition.B2Tab.style){
                    style = this.props.uiDefinition.B2Tab.style;
                }else{
                    style = undefined;
                }

                return <Tab label={this.props.uiDefinition.B2Tab.title} key={this.porps.uiDefinition.B2Tab.id} value={this.props.uiDefinition.B2Tab.id} style={style}/>;
            }
        }

        return;
    }

    getMainTabId(){
        if (this.props.uiDefinition.B2Tab){
            return (
                Array.isArray(this.props.uiDefinition.B2Tab)?
                    this.props.uiDefinition.B2Tab[0].id
                :
                this.props.uiDefinition.B2Tab.id
            );
        }

        return;

    }

    generateTab(tabToGenerateUIDefinition){
        const {uiDefinition, ...newProps} = this.props;
        return <B2Tab key={tabToGenerateUIDefinition.id} {...newProps} uiDefinition={tabToGenerateUIDefinition} />
    }

    generateActiveTab(){
        if (this.props.uiDefinition.B2Tab){
            if (Array.isArray(this.props.uiDefinition.B2Tab)){
                const activeTab = this.props.uiDefinition.B2Tab.filter((b2TabDefinition, index) => {
                                            if (b2TabDefinition.id === this.props.beans[this.props.uiDefinition.id].tabId){
                                                return true
                                            }
                                            return false;
                                        }
                                    ).map ( (activeB2TabDefinition) => this.generateTab(activeB2TabDefinition));

                if (activeTab != null && activeTab.length > 0) {
                    return activeTab;
                }

                if (this.props.uiDefinition.B2Tab.length >0){
                    return this.generateTab(this.props.uiDefinition.B2Tab[0]);
                }
            }else{  //if (this.props.uiDefinition.id === this.props.beans[this.props.uiDefinition.id].tabId) {
                return this.generateTab(this.props.uiDefinition);
            }
        }

        return;
    }

    render() {
        let tabId = null;

        if (typeof(this.props.beans[this.props.uiDefinition.id].tabId) === 'undefined' || this.props.beans[this.props.uiDefinition.id].tabId === null){
            tabId = this.getMainTabId();
        }else {
            tabId = this.props.beans[this.props.uiDefinition.id].tabId;
        }

        return (
            <div className="w-100" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabId}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.generateTabHeaders()}
                    </Tabs>
                </AppBar>

                {this.generateActiveTab()}
            </div>
        );
    }
}

export default B2Tabs;