import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';

class B2Card extends React.Component {
    render() {
        return (
            <div className="row" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} style={{"margin" : "0px", "padding": "0px"}}>
                     {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
            </div>
        );
    }
}

export default B2Card;