import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
//import TableFooter from '@material-ui/core/TableFooter';
//import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';

import {ON_RECORD_SELECTED} from 'constants/B2ActionTypes';

class SafeeTableBody extends React.Component {

    recordSelected(event,record){
        event.stopPropagation();
        const b2Event = {
            type: ON_RECORD_SELECTED,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: record.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }
    render() {
        const pageBeginIndex = (this.props.beans[this.props.parentUiDefinition.id].currentPage * this.props.beans[this.props.parentUiDefinition.id].recordsPerPage);
        const pageLastIndex = (
                                this.props.datasource.entityList && (pageBeginIndex + this.props.beans[this.props.parentUiDefinition.id].currentPage) > this.props.datasource.entityList.totalCount
                              )?
                                this.props.datasource.entityList.totalCount:
                                (pageBeginIndex + this.props.beans[this.props.parentUiDefinition.id].recordsPerPage);
        return (<TableBody key={this.props.uiDefinition.id}>
                    <TableRow>
                        <TableCell>
                            <Table style={{tableLayout: 'auto'}} key={this.props.uiDefinition.id+"_tablebody"} id={this.props.uiDefinition.id+"_tablebody"}>
                                {   (typeof(this.props.datasource) === 'undefined' || this.props.datasource === null ||
                                    typeof(this.props.datasource.entityList) === 'undefined' || this.props.datasource.entityList === null ||
                                    this.props.datasource.entityList.totalCount === 0 )?
                                        (
                                            <React.Fragment>
                                                <TableHead>
                                                    <TableRow key={this.props.uiDefinition.id+"_HeadTableRowNoContent"} id={this.props.uiDefinition.id+"_HeadTableRowNoContent"}>
                                                        {getB2ChildrenComponents({...this.props, isHeader: true}, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                                                    </TableRow>
                                                    { (this.props.toggleTableFilter) &&
                                                        <TableRow key={this.props.uiDefinition.id+"_HeadTableFilterRowNoContent"} id={this.props.uiDefinition.id+"_HeadTableFilterRowNoContent"}>
                                                            {getB2ChildrenComponents({...this.props, isFilter: true}, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                                                        </TableRow>
                                                    }
                                                </TableHead>
                                                <TableBody>
                                                     <TableRow>
                                                        <TableCell align="center" padding="none" colSpan={
                                                                    (this.props.uiDefinition.SafeeTableCol)?
                                                                        Array.isArray(this.props.uiDefinition.SafeeTableCol)?
                                                                            this.props.uiDefinition.SafeeTableCol.length
                                                                            :
                                                                            1
                                                                        :
                                                                        1
                                                                    }>
                                                            <span style={{lineHeight: "38pt"}}>{
                                                                (
                                                                    this.props.noContentMessage?
                                                                        this.props.noContentMessage:
                                                                        (
                                                                            ( typeof(this.props.datasource.entityList) !== 'undefined' &&
                                                                                this.props.datasource.entityList !== null &&
                                                                                typeof(this.props.datasource.entityList.noContentMessage) !== 'undefined' &&
                                                                                this.props.datasource.entityList.noContentMessage !== null
                                                                                )? this.props.datasource.entityList.noContentMessage: "No content available"
                                                                        )
                                                                )
                                                            }</span>
                                                         </TableCell>
                                                     </TableRow>
                                                </TableBody>
                                            </React.Fragment>
                                        )
                                        :
                                        (
                                            <React.Fragment>
                                                <TableHead>
                                                    <TableRow key={this.props.uiDefinition.id+"_HeadTableRow"} id={this.props.uiDefinition.id+"_HeadTableRow"}>
                                                        {getB2ChildrenComponents({...this.props, isHeader: true}, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                                                    </TableRow>
                                                    { (this.props.toggleTableFilter) &&
                                                        <TableRow key={this.props.uiDefinition.id+"_HeadTableFilterRow"} id={this.props.uiDefinition.id+"_HeadTableFilterRow"}>
                                                            {getB2ChildrenComponents({...this.props, isFilter: true}, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                                                        </TableRow>
                                                    }
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        this.props.datasource.entityList.data.map((record,recordIndex) => {
                                                                if (recordIndex >= pageBeginIndex && recordIndex<pageLastIndex){
                                                                    const newProps = {
                                                                        ...this.props,
                                                                        isHeader: false,
                                                                        index: recordIndex,
                                                                        pageBeginIndex: pageBeginIndex
                                                                    }
                                                                    return (<TableRow
                                                                                hover
                                                                                selected={(this.props.beans[this.props.app.appBody.id].currentPage!==null && this.props.beans[this.props.app.appBody.id].currentPage==='main' && record.selected==="true")}
                                                                                onClick={(event) => this.recordSelected(event, record)} key={this.props.uiDefinition.id+"_tablerow_"+record.attributes.id.value} id={this.props.uiDefinition.id+"_tablerow_"+record.attributes.id.value}>
                                                                                {getB2ChildrenComponents({...newProps, isHeader: false, rowDatasource: record}, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                                                                            </TableRow>
                                                                    )
                                                                }

                                                                return null;
                                                            }
                                                        )
                                                    }
                                                </TableBody>
                                            </React.Fragment>
                                        )
                                }

                            </Table>
                        </TableCell>
                    </TableRow>
                </TableBody>
        )
    };
}

export default SafeeTableBody;
