import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import {Modal, ModalHeader} from 'reactstrap';
import {CLOSE_DIALOG} from 'constants/B2ActionTypes';

class B2Dialog extends React.Component {
    onCloseDialog = () => {
        const b2Event = {
            type: CLOSE_DIALOG,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id
            }
        }

        this.props.processB2Event(b2Event);
    }

    render() {
        return  <Modal className="modal-box" isOpen={typeof(this.props.beans[this.props.uiDefinition.id].isOpen)!=='undefined' && this.props.beans[this.props.uiDefinition.id].isOpen === "true"} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                    <ModalHeader className="modal-box-header bg-primary text-white">
                        {this.props.uiDefinition.title}
                    </ModalHeader>
                    {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
               </Modal>
    }
}

export default B2Dialog;