import React from 'react';

class B2Label extends React.Component {

    render() {

        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = undefined;
        }

        let className = null;
        if (this.props.uiDefinition.class){
            className = this.props.uiDefinition.class;
        }else{
            className = undefined;
        }

        return (
            <span key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} className={className} style={style}>{this.props.uiDefinition.value}</span>
        );
    }
}

export default B2Label;