import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

class B2TableButtonGroup extends React.Component {
    render() {
        return <TableBody key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                    <TableRow>
                         <TableCell style={this.props.uiDefinition.align?{textAlign: this.props.uiDefinition.align}:{}}>
                            <div>
                                {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                            </div>
                         </TableCell>
                    </TableRow>
                </TableBody>
    }
}

export default B2TableButtonGroup;