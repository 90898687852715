import {all, call, put, takeEvery, select} from 'redux-saga/effects';
import axios from 'axios';
import {sagaActionResponseCallBack} from 'actions/B2App';
import {TOKEN_KEY, LONG_OPERATION} from 'constants/B2ActionTypes';
import {PROCESS_SAFEE_DOWNLOAD_EWD_EVENT} from 'constants/SafeeActionTypes';
import {getAppNameFromPath, setCookie} from 'util/B2Utils';
//import {store} from 'MainApp';
import connConfig from '../connection/config.js';

// TODO: url below to be updated with more React way
var fullUrl = window.location.href;
var arr = fullUrl.split("/");
var protocol = (connConfig.protocol === "" || !connConfig.protocol)? (arr[0] + "//"):(connConfig.protocol+"://");
var host = arr[2].split(":")[0];
host = (connConfig.host === "" || !connConfig.host)?host:connConfig.host;
var port = arr[2].split(":").length = 1?"":(":"+arr[2].split(":")[1]);
port = (connConfig.port === "" || !connConfig.port)?port:":"+connConfig.port;
const url = protocol + host + port + connConfig.contextRoot;

const API_BASE_URL = url + (url.endsWith('/')?'rest/es/safee/ewd/':'/rest/es/safee/ewd/');

axios.defaults.withCredentials = true;

const sendUIEvent = async (eventAction) =>
    axios({
      url: API_BASE_URL+eventAction.eventDetails.userId+"?fromDate="+((eventAction.eventDetails.fromDate)?eventAction.eventDetails.fromDate:"")+"&toDate="+((eventAction.eventDetails.toDate)?eventAction.eventDetails.toDate:"")+"&format="+((eventAction.eventDetails.format)?eventAction.eventDetails.format:""),
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.target = "_blank";

        link.setAttribute('download',  response.headers["content-disposition"].split("filename=")[1]); //any other extension

        document.body.appendChild(link);

        link.click();

        link.remove();

        const token = response.headers['x-auth-token'];

        if (token){
            const updatedRes = {
                "type": eventAction.type
            }

            updatedRes[TOKEN_KEY] = token;
            return updatedRes;
        }else{
            return  {
                "appName": eventAction.appName,
                "type": eventAction.type,
                "actionResponse" : {}
            }
        }
    })
    .catch(function (error) {
        if (error.response && error.response instanceof Blob){
            const errorResponse = {
                "appName": eventAction.appName,
                "type": eventAction.type,
                "errors": [
                    {
                        "display": "popup",
                        "code":(error.response)?error.response.status:"",
                        "message": (error.response)?error.response.data:error
                    }
                ]
            }

            return errorResponse; //TODO: try different approaches to handle error
        }else{
            const errorResponse = {
                "appName": eventAction.appName,
                "type": eventAction.type,
                "errors": [
                    {
                        "display": "popup",
                        "code":(error.response)?error.response.status:"",
                        "message": "Unable to download file. Please try again later or contact system administrator"
                    }
                ]
            }

            return errorResponse; //TODO: try different approaches to handle error
        }

    });

function* processDownloadEWD(eventAction){
//    console.log('=========Saga Download Attachment Request Received - Print Beginning =========');
//    console.log(eventAction);
//    console.log('=========Saga Download Attachment Request Received - Print End ========');

    const eventRequest = eventAction.eventRequest;

    let eventActionUpdate = {
        "appName": getAppNameFromPath(yield select(state => state.router.location.pathname)),
        ...eventRequest,
    }

    if (eventRequest.eventDetails && eventRequest.eventDetails.longOperation){
        yield put(
            sagaActionResponseCallBack({
                    "type": LONG_OPERATION,
                    "longOperation": eventRequest.eventDetails.longOperation,
                    "longOperationText": eventRequest.eventDetails.longOperationText
                }
            )
        );
    }

    const uiEventResponseJSONObj = yield call(sendUIEvent, eventActionUpdate);

    if (uiEventResponseJSONObj[TOKEN_KEY]){
        localStorage.setItem('userToken', uiEventResponseJSONObj[TOKEN_KEY]);
        setCookie("x-auth-token", uiEventResponseJSONObj[TOKEN_KEY], 30);
        yield setJwt(uiEventResponseJSONObj[TOKEN_KEY]);
    }

//    console.log('=========Saga Download Attachment Response Received - Print Beginning =========');
//    console.log(uiEventResponseJSONObj);
//    console.log('=========Saga Download Attachment Response Received - Print End ========');

    yield put(
        sagaActionResponseCallBack(
            {
                ...uiEventResponseJSONObj,
                "longOperation": false,
                "longOperationText": ''
            }
        )
    );
}

export function setJwt(jwt) {
    axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default function* rootSaga() {
    try{
        yield all(
            [
                takeEvery(PROCESS_SAFEE_DOWNLOAD_EWD_EVENT, processDownloadEWD)
            ]
        )
    }catch (error){
        yield call(sagaActionResponseCallBack, error);
    }
}