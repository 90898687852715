import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar';
import {hasFeatureAccess} from 'util/B2Utils';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import {ON_TOGGLE_BOOKMARK_RECORD, ON_CHANGE_APP_PAGE, ON_APP_TOGGLE_DRAWER/*, SAVE_RECORD*/} from 'constants/B2ActionTypes';

class B2ToolBar extends React.Component {
    sendToolbarEvent = (event, menuItem) => {
        event.stopPropagation();
        const b2Event = {
            type: menuItem.keyValue,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id,
                keyValue: menuItem.keyValue
            }
        }

        this.props.processB2Event(b2Event);
    }

    onBookmarkSelect(event,record){
        event.stopPropagation();
        const b2Event = {
            type: ON_TOGGLE_BOOKMARK_RECORD,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: record.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    changeAppPage = (page) => {
        const b2Event = {
            type: ON_CHANGE_APP_PAGE,
            eventDetails: {
                page: page
            }
        }
        this.props.processB2Event(b2Event);
    }

    onAppToggleDrawer() {
        const b2Event = {
            type: ON_APP_TOGGLE_DRAWER,
            eventDetails: {
            }
        }

        this.props.processB2Event(b2Event);
    }

    isToolbarActionDisabled(toolbarAction) {
        if (toolbarAction.disabledCondition){
            return eval(toolbarAction.disabledCondition);
        }

        return false;
    }

    getRecordActions = () => {
        return <div className="d-flex">
                    {/*<IconButton onClick={(event) => {this.onRecordSave(event);}} className="icon-btn"
                                disabled={typeof(this.props.beans[this.props.app.appBody.id].modified)==='undefined' ||
                                this.props.beans[this.props.app.appBody.id].modified==="false"}>
                        <i className="zmdi zmdi-floppy" title="Save"/>
                    </IconButton>*/}

                    {(this.props.beans[this.props.app.appBody.id].currentPage==='main' && (!this.props.uiDefinition.disableBookmark || this.props.uiDefinition.disableBookmark !=="true" )) && <IconButton type="button" className="icon-btn size-50 p-0" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                        onClick={(event) => {
                            this.onBookmarkSelect(event, this.props.datasource.entityList.selectedRecord);
                        }}>
                        {(typeof(this.props.datasource.entityList.selectedRecord) !== 'undefined' &&
                        (typeof(this.props.beans[this.props.app.appBody.id].bookmarkedRecords)!== 'undefined') &&
                        this.props.beans[this.props.app.appBody.id].bookmarkedRecords.includes(this.props.datasource.entityList.selectedRecord.attributes.id.value)) ?
                            <i className="zmdi zmdi-star"/> :
                            <i className="zmdi zmdi-star-outline"/>
                        }
                    </IconButton>}

                    {/*(this.props.beans[this.props.app.appBody.id].currentPage==='main') && <IconButton onClick={this.onDeleteRecord} className="icon-btn">
                        <i className="zmdi zmdi-delete"/>
                    </IconButton>*/}

                    { this.getToobarFeatures() }
                </div>
    };

    getToobarFeatures = () => {
        if (typeof(this.props.toolbarActions) === 'undefined' || this.props.toolbarActions === null || this.props.toolbarActions.totalCount === 0){
            return;
        }
        return this.props.toolbarActions.map((toolbarFeature, index) => {
                 if (toolbarFeature.menuType === 'TOOLBAR' && (toolbarFeature.page === 'all' || toolbarFeature.page === this.props.beans[this.props.app.appBody.id].currentPage )){
                    if (toolbarFeature.elementType === 'FEATURE' && hasFeatureAccess(this.props.datasources[this.props.beans[this.props.app.appBody.id].datasource], toolbarFeature.keyValue) && toolbarFeature.visible){
                        return <IconButton id={toolbarFeature.id} key={toolbarFeature.id} onClick={(event) => {this.sendToolbarEvent(event, toolbarFeature);}} className="icon-btn"
                                disabled={this.isToolbarActionDisabled(toolbarFeature)}
                        >
                            <i className={`zmdi zmdi-${toolbarFeature.image}`} title={toolbarFeature.description}/>
                        </IconButton>
                    }else if (toolbarFeature.menuType === 'SEPARATOR'){
                        return null; // TODO: implement separator functionality later
                    }
                }

                return null;
            }
        )
    }

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            searchBox: false
        };
    }

    render() {
        if (this.props.beans[this.props.app.appBody.id].currentPage===null || this.props.beans[this.props.app.appBody.id].currentPage==='list'){
            return (<div className="module-box-header" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                        {/*The below icon opens the drawer (for small screens where action menu is displayed. It should ideally not be displayed if action menu is not included in the application, but we can not think of an application with a tool bar (current component) that does not have a drawer menu. If in the future we need to remove show or hide this icon based on application JSON flags, we will add this*/}
                        <IconButton className="drawer-btn d-block d-xl-none" aria-label="Menu"
                            onClick={this.onAppToggleDrawer.bind(this)}>
                            <i className="zmdi zmdi-menu"/>
                        </IconButton>

                        <div className="module-box-header-inner">
                            {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}

                            {this.getRecordActions()}
                        </div>

                    </div>
                );
        } else if (this.props.beans[this.props.app.appBody.id].currentPage==='main'){
            return <Toolbar className="module-box-topbar" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                        <IconButton className="icon-btn"
                            onClick={() => {
                                this.changeAppPage("list");
                            }}
                            >
                            <i className="zmdi zmdi-arrow-back"/>
                        </IconButton>
                        {this.getRecordActions()}
                        <div className="spacer"/>
                    </Toolbar>

        }
        return <React.Fragment key={this.props.uiDefinition.id}/>;
    }
}

export default B2ToolBar;
