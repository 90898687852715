import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';

class B2GridCell extends React.Component {
    render() {
        const className=this.props.uiDefinition.classPrefix+this.props.uiDefinition.widthRatio+' col-12';

        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = { "padding" : "0px 5px"}
        }

        return (
            <div key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} className={className} style={style}>
                {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
            </div>
        );
    }
}

export default B2GridCell;