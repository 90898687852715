import {all, call, put, takeEvery, select} from 'redux-saga/effects';
import axios from 'axios';
import {sagaActionResponseCallBack} from 'actions/B2App';
import {
    PROCESS_B2_EVENT,
    PROCESS_B2_LOCATION_EVENT,
    TOKEN_KEY,
    PROCESS_B2_SECURITY_EVENT,
    SIGNUP_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD,
    ACTIVATE_ACCOUNT,
    LONG_OPERATION
} from 'constants/B2ActionTypes';

import {
    RESEND_ACTIVE_LINK
} from "constants/SafeeActionTypes";

import {getAppNameFromPath} from 'util/B2Utils';
import {LOAD_B2_APP} from 'constants/B2ActionTypes';
import {store} from 'MainApp';
import connConfig from './connection/config.js';
import {setCookie, getCookie, eraseCookie} from 'util/B2Utils.js';

var fullUrl = window.location.href;
var arr = fullUrl.split("/");
var protocol = (connConfig.protocol === "" || !connConfig.protocol)? (arr[0] + "//"):(connConfig.protocol+"://");
var host = arr[2].split(":")[0];
host = (connConfig.host === "" || !connConfig.host)?host:connConfig.host;
var port = arr[2].split(":").length = 1?"":(":"+arr[2].split(":")[1]);
port = (connConfig.port === "" || !connConfig.port)?port:":"+connConfig.port;
const url = protocol + host + port + connConfig.contextRoot;

const API_BASE_URL = url + (url.endsWith('/')?'rest/es/uievents':'/rest/es/uievents');
const API_AUTH_URL = url + (url.endsWith('/')?'rest/auth':'/rest/auth');
const API_ACTIVATE_URL = url +(url.endsWith('/')?'rest/activate':'/rest/activate');
const API_RESEND_ACTIVATE_URL = url +(url.endsWith('/')?'rest/send-activation-link':'/rest/send-activation-link');

if (getJwt()){
    setJwt(getJwt());
}

axios.defaults.withCredentials = true;

export function setJwt(jwt) {
    axios.defaults.headers.common["x-auth-token"] = jwt;
}

export function getJwt() {
    if (!localStorage.getItem(TOKEN_KEY)){
        return null;
    }
    return localStorage.getItem(TOKEN_KEY);
}

const createUserWithEmailPasswordRequest = async (eventAction) =>
    await axios.post(
        API_AUTH_URL + '/signup',
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        const token = res.headers['x-auth-token'];
        const updatedRes = {
            ...res.data
        }
        updatedRes[TOKEN_KEY] = token;

        return updatedRes;
    }).catch(function (error) {
        const errorResponse = {
            "type": eventAction.type,
            "errors": [
                {
                    "code":error.response.status,
                    "display": "popup",
                    "message": error.response.data
                }
            ]
        }

        return errorResponse;
    }
);

const signInUserWithEmailPasswordOrTokenRequest = async (eventAction) =>
    await axios.post(
        API_AUTH_URL + '/login',
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        const token = res.headers['x-auth-token'];
        if (token){
            const updatedRes = {
                ...res.data
            }
            updatedRes[TOKEN_KEY] = token;

            return updatedRes;
        }else{
            return res.data;
        }

    }).catch(function (error) {
        const errorResponse = {
            "type": eventAction.type,
            "errors": [
                {
                    "display": "popup",
                    "code":(error.response.status)?error.response.status:error.response,
                    "message": error.response.data
                }
            ]
        }

        return errorResponse;
    }
);

const signOut = async (eventAction) =>
    await axios.post(
        API_AUTH_URL + '/logout',
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        localStorage.removeItem(TOKEN_KEY);
        const updatedRes = {
            ...res.data
        }
        return updatedRes;
    }).catch(function (error) {
        const errorResponse = {
            "type": eventAction.type,
            "errors": [
                {
                    "display": "popup",
                    "code":error.response.status,
                    "message": error.response.data
                }
            ]
        }

        return errorResponse;
    }
);

const activate = async (eventAction) =>
    await axios.post(
        API_ACTIVATE_URL,
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        const token = res.headers['x-auth-token'];
        if (token){
            const updatedRes = {
                ...res.data
            }
            updatedRes[TOKEN_KEY] = token;
            return updatedRes;
        }else{
            return res.data;
        }
    }).catch(function (error) {
            const errorResponse = {
                "type": eventAction.type,
                "errors": [
                    {
                        "code":error.response.status,
                        "message": error.response.data
                    }
                ]
            }
            return errorResponse;
        }
    );

const resendActivationLink = async (eventAction) =>
    await axios.post(
        API_RESEND_ACTIVATE_URL,
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        const updatedRes = {
            ...res.data
        }
        return updatedRes;
    }).catch(function (error) {

            const errorResponse = {
                "type": eventAction.type,
                "errors": [
                    {
                        "code":error.response.status,
                        "message": error.response.data
                    }
                ]
            }
            return errorResponse;
        }
    );

const resetPassword = async (eventAction) =>
    await axios.post(
        API_AUTH_URL + '/reset-password',
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        const updatedRes = {
            ...res.data
        }
        return updatedRes;
    }).catch(function (error) {
            const errorResponse = {
                "type": eventAction.type,
                "errors": [
                    {
                        "display": "popup",
                        "code":error.response.status,
                        "message": error.response.data
                    }
                ]
            }
            return errorResponse;
        }
    );

const resetPasswordRequest = async (eventAction) =>
    await axios.post(
        API_AUTH_URL + '/reset-password-request',
        eventAction,
        { crossdomain: true }
    ).then((res) => {
        const updatedRes = {
            ...res.data
        }
        return updatedRes;
    }).catch(function (error) {
            const errorResponse = {
                "type": eventAction.type,
                "errors": [
                    {
                        "display": "popup",
                        "code":error.response.status,
                        "message": error.response.data
                    }
                ]
            }
            return errorResponse;
        }
    );

const sendUIEvent = async (eventAction/*, additionalHeaders*/) => await axios.post(
        API_BASE_URL,
        eventAction,
        {
            crossdomain: true
//            ,
//            headers: (additionalHeaders==null)?axios.defaults.headers.common:additionalHeaders
        }
    )
    .then((res) => {
        const token = res.headers['x-auth-token'];
        if (token){
            const updatedRes = {
                ...res.data
            }
            updatedRes[TOKEN_KEY] = token;
            return updatedRes;
        }else{
            return res.data;
        }
    })
    .catch(function (error) {
        if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 440)){   // remove this condition and its content if you want error message to appear (session timeout or access denied)
            localStorage.removeItem(TOKEN_KEY);
            window.location='/signin';
            return {};
        }

        const errorResponse = {
            "appName": eventAction.appName,
            "type": eventAction.type,
            "errors": [
                {
                    "display": "popup",
                    "code":(error.response)?error.response.status:"",
                    "message": (error.response)?error.response.data:error
                }
            ]
        }

        return errorResponse; //TODO: try different approaches to handle error
    });

function* processSecurityEvent(eventAction){
//    console.log('=========Saga Security Event Received - Print Beginning =========');
//    console.log(eventAction);
//    console.log('=========Saga Security Event Received - Print End========');

    const eventRequest = eventAction.eventRequest;
    let securityEventResponseJSONObj = {};
    switch (eventRequest.type) {
        case RESET_PASSWORD:{
            securityEventResponseJSONObj = yield call(resetPassword, eventRequest);
            break;
        }
        case ACTIVATE_ACCOUNT:{
            securityEventResponseJSONObj = yield call(activate, eventRequest);

            if (securityEventResponseJSONObj[TOKEN_KEY]){
                localStorage.setItem(TOKEN_KEY, securityEventResponseJSONObj[TOKEN_KEY]);
                yield setJwt(securityEventResponseJSONObj[TOKEN_KEY]);
            }
            break;
        }
        case RESEND_ACTIVE_LINK:{
            securityEventResponseJSONObj = yield call(resendActivationLink, eventRequest);
            break;
        }
        case RESET_PASSWORD_REQUEST:{
            securityEventResponseJSONObj = yield call(resetPasswordRequest, eventRequest);
            break;
        }
        case SIGNUP_USER:{
            securityEventResponseJSONObj = yield call(createUserWithEmailPasswordRequest, eventRequest);

            if (securityEventResponseJSONObj[TOKEN_KEY]){
                localStorage.setItem(TOKEN_KEY, securityEventResponseJSONObj[TOKEN_KEY]);
                setCookie("x-auth-token", securityEventResponseJSONObj[TOKEN_KEY], 30);
                yield setJwt(securityEventResponseJSONObj[TOKEN_KEY]);
            }
            break;
        }
        case SIGNIN_USER:{
            securityEventResponseJSONObj = yield call(signInUserWithEmailPasswordOrTokenRequest, eventRequest);

            if (securityEventResponseJSONObj[TOKEN_KEY]){
                localStorage.setItem(TOKEN_KEY, securityEventResponseJSONObj[TOKEN_KEY]);
                setCookie("x-auth-token", securityEventResponseJSONObj[TOKEN_KEY], 30);
                yield setJwt(securityEventResponseJSONObj[TOKEN_KEY]);
            }
            break;
        }
        case SIGNOUT_USER:{
            securityEventResponseJSONObj = yield call(signOut, eventRequest);
            localStorage.removeItem(TOKEN_KEY);
            eraseCookie("x-auth-token");
            window.location='/signin';
            break;
        }
    }

//    console.log('=========Saga Security Event Response Received - Print Beginning =========');
//    console.log(securityEventResponseJSONObj);
//    console.log('=========Saga Security Event Response Received - Print End ========');

    let callback = null;
    if (eventRequest.callback !== null){
        callback = eventRequest.callback;
        eventRequest.callback = null;
    }

    if (callback !== null){
         yield put(
            sagaActionResponseCallBack({
                    ...securityEventResponseJSONObj,
                    "callback": callback
                }
            )
        );
    }else{
        yield put(sagaActionResponseCallBack(securityEventResponseJSONObj));
    }
}

function* processEvent(eventAction){

//    console.log('=========Saga Event Received - Print Beginning =========');
//    console.log(eventAction);
//    console.log('=========Saga Event Received - Print End ========');

    const eventRequest = eventAction.eventRequest;

    let appPath = getAppNameFromPath(yield select(state => state.router.location.pathname));

    let eventActionUpdate = {
        "appName": appPath,
        ...eventRequest,
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
                      get: (searchParams, prop) => searchParams.get(prop),
                    });

//    let tokenQueryParam = params['x-auth-token'];
//
//    let additionalHeaders = null;
//
//    if (tokenQueryParam && ''!==tokenQueryParam){
//       additionalHeaders = {
//           'x-auth-token-url' : tokenQueryParam
//       };
//    }

    let callback = null;
    if (eventRequest.callback !== null){
        callback = eventRequest.callback;
        eventRequest.callback = null;
    }
    
    if (eventRequest.eventDetails && eventRequest.eventDetails.longOperation){
        yield put(
            sagaActionResponseCallBack({
                    "type": LONG_OPERATION,
                    "longOperation": eventRequest.eventDetails.longOperation,
                    "longOperationText": eventRequest.eventDetails.longOperationText
                }
            )
        );
    }

    const uiEventResponseJSONObj = yield call(sendUIEvent, eventActionUpdate/*, additionalHeaders*/);

    if (uiEventResponseJSONObj[TOKEN_KEY]){
        localStorage.setItem('userToken', uiEventResponseJSONObj[TOKEN_KEY]);
        setCookie("x-auth-token", uiEventResponseJSONObj[TOKEN_KEY], 30);
        setJwt(uiEventResponseJSONObj[TOKEN_KEY]);
    }

//    console.log('=========Saga Event Response Received - Print Beginning =========');
//    console.log(uiEventResponseJSONObj);
//    console.log('=========Saga Event Response Received - Print End ========');

    if (callback !== null){
         yield put(
            sagaActionResponseCallBack({
                    ...uiEventResponseJSONObj,
                    "callback": callback,
                    "longOperation": false,
                    "longOperationText": ''
                }
            )
        );
    }else{
        yield put(
            sagaActionResponseCallBack(
                {
                    ...uiEventResponseJSONObj,
                    "longOperation": false,
                    "longOperationText": ''
                }
            )
        );
    }

}

function* processLocationEvent(eventAction){
//    console.log('=========Saga Location Event Received - Print Beginning =========');
//    console.log(eventAction);
//    console.log('=========Saga Location Event Received - Print End ========');

    if (eventAction.payload.location.pathname.toLowerCase() === '/signin' &&
            eventAction.payload.location.search &&
            eventAction.payload.location.search !== '' &&
            eventAction.payload.location.search !== '?'
    ){

        const params = new Proxy(new URLSearchParams(window.location.search), {
                              get: (searchParams, prop) => searchParams.get(prop),
                            });

        let tokenQueryParam = params['x-auth-token'];

        if (!tokenQueryParam || ''===tokenQueryParam){
            return;
        }

        const b2SecEvent = {
            type: SIGNIN_USER,
            eventDetails: {
                token: tokenQueryParam
            }
        }

        let securityEventResponseJSONObj = yield call(signInUserWithEmailPasswordOrTokenRequest, b2SecEvent);

        if (securityEventResponseJSONObj[TOKEN_KEY]){
            localStorage.setItem(TOKEN_KEY, securityEventResponseJSONObj[TOKEN_KEY]);
            setCookie("x-auth-token", securityEventResponseJSONObj[TOKEN_KEY], 30);
            yield setJwt(securityEventResponseJSONObj[TOKEN_KEY]);
        }

        //    console.log('=========Saga Location Event Response Received - Print Beginning =========');
        //    console.log(uiEventResponseJSONObj);
        //    console.log('=========Saga Location Event Response Received - Print Ended ========');

        yield put(sagaActionResponseCallBack(securityEventResponseJSONObj));

        return;
    }else if (eventAction.payload.location.pathname.toLowerCase() === '/activate' ||
         eventAction.payload.location.pathname.toLowerCase().startsWith('/activate?')){

        const params = new Proxy(new URLSearchParams(window.location.search), {
                              get: (searchParams, prop) => searchParams.get(prop),
                            });

        let tokenValue = params['token'];

         const b2SecEvent = {
             type: ACTIVATE_ACCOUNT,
             eventDetails: {
                 token: tokenValue
             }
         }

         let securityEventResponseJSONObj = yield call(activate, b2SecEvent);

        if (securityEventResponseJSONObj[TOKEN_KEY]){
            localStorage.setItem(TOKEN_KEY, securityEventResponseJSONObj[TOKEN_KEY]);
            yield setJwt(securityEventResponseJSONObj[TOKEN_KEY]);
        }

        yield put(sagaActionResponseCallBack(securityEventResponseJSONObj));

        return;
    }else if (eventAction.payload.location.pathname.toLowerCase() === '/signin' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/signin?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/signup' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/signup?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/forgotpassword' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/forgotpassword?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/resetpassword'||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/resetpassword?') ||
//        eventAction.payload.location.pathname.toLowerCase() === '/activate' ||
//        eventAction.payload.location.pathname.toLowerCase().startsWith('/activate?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/app/b2/' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/app/b2/?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/app/b2' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/app/b2?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/app' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/app?') ||
        eventAction.payload.location.pathname.toLowerCase() === '/app/' ||
        eventAction.payload.location.pathname.toLowerCase().startsWith('/app/?')
        ) {
//        console.log('=========Saga Location Event Response Received - Print Beginning =========');
//        console.log('EVENT DISCARDED');
//        console.log('=========Saga Location Event Response Received - Print Ended ========');

        return;
    }else{

        const params = new Proxy(new URLSearchParams(window.location.search), {
                                      get: (searchParams, prop) => searchParams.get(prop),
                                    });

        let recordId = params['record_id'];

        let eventRequest = null;

        if (recordId == null){
            eventRequest =  {
                                 type: LOAD_B2_APP,
                                 eventDetails: {
                                    historyApp: (store.getState().b2State.app)? store.getState().b2State.app.attributes.appName.value: null
                                 }
                             }
        }else{
            eventRequest =  {
                                 type: LOAD_B2_APP,
                                 eventDetails: {
                                    historyApp: (store.getState().b2State.app)? store.getState().b2State.app.attributes.appName.value: null,
                                    recordId: recordId
                                 }
                             }
        }

        let eventActionUpdate = {
            "appName": getAppNameFromPath(yield select(state => state.router.location.pathname)),
            ...eventRequest,
        }

        let tokenQueryParam = params['x-auth-token'];

        let additionalHeaders = null;

        if (tokenQueryParam && ''!==tokenQueryParam){

            additionalHeaders = {
                'x-auth-token-url' : tokenQueryParam
            };
        }

        const uiEventResponseJSONObj = yield call(sendUIEvent, eventActionUpdate, additionalHeaders);

        if (uiEventResponseJSONObj[TOKEN_KEY]){
            localStorage.setItem(TOKEN_KEY, uiEventResponseJSONObj[TOKEN_KEY]);
            setCookie("x-auth-token", uiEventResponseJSONObj[TOKEN_KEY], 30);
            setJwt(uiEventResponseJSONObj[TOKEN_KEY]);
        }

//    console.log('=========Saga Location Event Response Received - Print Beginning =========');
//    console.log(uiEventResponseJSONObj);
//    console.log('=========Saga Location Event Response Received - Print Ended ========');

        yield put(sagaActionResponseCallBack(uiEventResponseJSONObj));


        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('record_id') != null){
            let queryStr = '';
            let amp = '';


            urlParams.forEach(
                (value, key) => {
                    if (key.toLowerCase() != 'record_id'){
                        queryStr += amp + key + '='+ value;
                        amp = '&';
                    }
                }
            )

            if (queryStr === ''){
                window.history.pushState({}, null, window.location.pathname);
            }else{
                window.history.pushState({}, null, window.location.pathname + '?' + queryStr);
            }
        }
    }

}

export default function* rootSaga() {
    try{
        yield all(
            [
                takeEvery(PROCESS_B2_SECURITY_EVENT, processSecurityEvent),
                takeEvery(PROCESS_B2_EVENT, processEvent),
                takeEvery(PROCESS_B2_LOCATION_EVENT, processLocationEvent)
            ]
        )
    }catch (error){
        yield call(sagaActionResponseCallBack, error);
    }
}