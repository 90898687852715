import React from 'react';
import Button from '@material-ui/core/Button';
import {store} from 'MainApp';
import {DOWNLOAD_EWD, DOWNLOAD_UNLIMITED_EWD} from 'constants/SafeeActionTypes';
import moment from 'moment';

class SafeeEWDDownloadButton extends React.Component {
    onDownloadEWD(event){

        let fromDate = store.getState().b2State.datasources[this.props.datasource.id+".WorkDiaryFilterNP"].entityList.selectedRecord.attributes.fromDate.value;
        if (fromDate && fromDate !== null){
            fromDate = moment(fromDate).format("YYYY-MM-DD");
        }

        let toDate = store.getState().b2State.datasources[this.props.datasource.id+".WorkDiaryFilterNP"].entityList.selectedRecord.attributes.toDate.value;
        if (toDate && toDate !== null){
            toDate = moment(toDate).format("YYYY-MM-DD");
        }

        event.stopPropagation();

        const safeeEvent = {
            type: this.props.uiDefinition.event,
            eventDetails: {
                fromDate: fromDate,
                toDate: toDate,
                userId: this.props.datasource.entityList.selectedRecord.attributes.userId.value,
                format: this.props.uiDefinition.format,
                longOperation: this.props.uiDefinition.longOperation,
                longOperationText: this.props.uiDefinition.longOperationText,
            }
        }

        this.props.processDownloadEWDSafeeEvent(safeeEvent);
    }

    render() {
        let style = null;
        if (this.props.style){
            style = this.props.style;
        } else if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = { "margin" : "12px 7px"}
        }

        // TODO: handle button icon
        return ( <Button variant="contained" color="primary" className="jr-btn text-white"
                    onClick={(event) => {
                        this.onDownloadEWD(event);
                    }} style={style}>
                    <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/>
                    {this.props.uiDefinition.label}
            </Button>
        );
    }
}

export default SafeeEWDDownloadButton;