import React from 'react';
import {Link} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import B2TextField from '../B2TextField';
import {ON_TOGGLE_BOOKMARK_RECORD, TOGGLE_TABLE_DETAILS, DOWNLOAD_ATTACHMENT} from 'constants/B2ActionTypes';

class B2Hyperlink extends React.Component {
    onDownloadAttachment(event,record){
        event.stopPropagation();
        const b2Event = {
            type: DOWNLOAD_ATTACHMENT,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: record.attributes.id.value
            }
        }

        this.props.processDownloadAttachmentB2Event(b2Event);
    }

    onDownloadAttachmentByAttachmentId(event,attachmentId){
        event.stopPropagation();
        const b2Event = {
            type: DOWNLOAD_ATTACHMENT,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: attachmentId,
                longOperation: this.props.uiDefinition.longOperation,
                longOperationText: this.props.uiDefinition.longOperationText,
            }
        }

        this.props.processDownloadAttachmentB2Event(b2Event);
    }

    getSortAttribute(){
        if (this.props.bean.sortAttribute){
            return this.props.bean.sortAttribute;
        }

        if (this.props.datasource.entityList.orderBy){
            const sortString = this.props.datasource.entityList.orderBy;
            let sortAttribute = sortString.trim();
            if (sortAttribute.endsWith(" asc")){
                sortAttribute = sortAttribute.substring(0, sortAttribute.length -4);
            } else if (sortAttribute.endsWith(" desc")){
                sortAttribute = sortAttribute.substring(0, sortAttribute.length -5);
            }
            return sortAttribute.trim(); // assuming sort happens with one attribute only (one column)
        }
        return null;
    }

    getSortDirection(){
        if (this.props.bean.sortAttribute){
            return this.props.bean.sortDirection;
        }

        if (this.props.datasource.entityList.orderBy){
            const sortString = this.props.datasource.entityList.orderBy;

            if (sortString.trim().endsWith(" asc")){
                return "asc";
            }
            if (sortString.trim().endsWith(" desc")){
                return "desc";
            }
            return "asc";
        }
        return null;
    }

    getLabel(){
        if (this.props.uiDefinition.label){
            return this.props.uiDefinition.label;
        }else if (this.props.datasource.entityList && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute] && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].title){
            return this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].title;
        } else {
            return this.props.uiDefinition.attribute;
        }
    }

    render() {
        let target = this.props.uiDefinition.target;
        if (!target){
            target = "_blank"
        }

        if (this.props.uiDefinition.attribute){
            if (this.props.isHeader){
                if (this.props.datasource.entityList && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute] && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].isPersistent === "true"){
                    const sortAttribute = this.getSortAttribute();
                    let sortDirection;
                    if (sortAttribute !== this.props.uiDefinition.attribute){
                        sortDirection = "asc";
                    }else{
                        sortDirection = this.getSortDirection();
                    }
                    return <Tooltip
                                title="Sort"
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={sortAttribute === this.props.uiDefinition.attribute}
                                    direction={(sortDirection)?sortDirection:"asc"}
                                    onClick={() => this.onListSort()}
                                >
                                    {this.getLabel()}
                               </TableSortLabel>
                            </Tooltip>
                }else{
                    return <React.Fragment key={this.props.uiDefinition.id}>{this.getLabel()}</React.Fragment>

                }
            }else if (this.props.isFilter){
                if (this.props.parentUiDefinition.filterable &&
                     this.props.datasource.entityList &&
                     this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute] &&
                     this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].isPersistent === "true"){
                     return <B2TextField {...this.props}  style={this.props.uiDefinition.innerComponentStyle}></B2TextField>
                 }else{
                     return <React.Fragment key={this.props.uiDefinition.id}/>;
                }
            }else{
                let style = this.props.uiDefinition.style;

                if (this.props.rowDatasource.toBeRemoved){
                    if (style){
                        style = { ...style };
                        style["textDecoration"] = "line-through";
                        style["fontStyle"] = "italic";
                        style["fontWeight"] = "bold";
                    }else{
                        style = {
                            "textDecoration": "line-through",
                            "fontStyle": "italic",
                            "fontWeight": "bold"
                        }
                    }
                }

                if (this.props.uiDefinition.attribute && this.props.rowDatasource.attributes[this.props.uiDefinition.attribute]){
                    return <Link to={this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value} target={target} download>{this.getLabel()}</Link>
                }else{
                    return <React.Fragment key={this.props.uiDefinition.id}/>;
                }
            }
        }else if (this.props.uiDefinition.value){
            let style = this.props.uiDefinition.style;
            if (this.props.uiDefinition.value.startsWith("http")){
                return <a href={this.props.uiDefinition.value} target={target} style={(style!=null && style !== "")?style:{}}>{this.getLabel()}</a>
            }else{
                return <Link to={this.props.uiDefinition.value} target={target} style={(style!=null && style !== "")?style:{}}>{this.getLabel()}</Link>
            }
        }else if (typeof(this.props.parentUiDefinition.attribute)==='undefined' &&
                 typeof(this.props.uiDefinition.onClick)!=='undefined' &&
                 this.props.uiDefinition.onClick==='downloadAttachment'){

                 let style = this.props.uiDefinition.style;
                 if (style){
                    style = { ...style }
                    if (!style.color){
                        style.color = "#3f51b5";
                    }
                    style.textDecoration= "none";
                    style.backgroundColor= "transparent";
                 }

                 let attachmentId = this.props.uiDefinition.attachmentId;

                 if (attachmentId){
                    return <a onClick={(event) => {
                                               this.onDownloadAttachmentByAttachmentId(event, attachmentId);
                                            }}
                                            style={(style!=null && style !== "")?
                                                style:{
                                                    color: "#3f51b5",
                                                    textDecoration: "none",
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer"
                                                }} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>{this.getLabel()}</a>

                 }else{

                      if (!this.props.isHeader){
                          // applies to a single record, usually for table row
                          return <a onClick={(event) => {
                                                    this.onDownloadAttachment(event, this.props.rowDatasource);
                                                 }}
                                                 style={(style!=null && style !== "")?style:{
                                                  color: "#3f51b5",
                                                  textDecoration: "none",
                                                  backgroundColor: "transparent",
                                                cursor: "pointer"
                                              }} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>{this.getLabel()}</a>


                      }else{
                          // icon in header of table,
                          // TODO: to be handled later if any
                          return <React.Fragment key={this.props.uiDefinition.id}/>
                      }
                 }

        }else{
            return <React.Fragment key={this.props.uiDefinition.id}/>;
        }
    }
}

export default B2Hyperlink;