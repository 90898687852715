import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import IntlMessages from 'util/IntlMessages';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import {hasFeatureAccess} from 'util/B2Utils';
import {ON_APP_TOGGLE_DRAWER,
//ON_CREATE_RECORD,
EXECUTE_QUERY} from 'constants/B2ActionTypes';

class B2ActionMenu extends React.Component {
//    onCreateRecord = () => {
//        const b2Event = {
//            type: ON_CREATE_RECORD,
//            eventDetails: {
//                beanId: this.props.bean.id,
//                datasourceId: this.props.datasource.id
//            }
//        }
//
//        this.props.processB2Event(b2Event);
//    }

    onAppToggleDrawer() {
        const b2Event = {
            type: ON_APP_TOGGLE_DRAWER,
            eventDetails: {
            }
        }

        this.props.processB2Event(b2Event);
    }

    onQuery(queryName){
        const b2Event = {
            type: EXECUTE_QUERY,
            eventDetails: {
                datasourceId: this.props.datasource.id,
                queryName: queryName
            }
        }

        this.props.processB2Event(b2Event);
    }

    getNavFilters = () => {
        if (!this.props.queries || typeof(this.props.queries) === 'undefined' || this.props.queries === null || this.props.queries.totalCount === 0){
            return;
        }

        return this.props.queries.map((query, index) =>
            <li key={index+1} onClick={() => {this.onQuery(query.queryName);}}>
                <span className="jr-link">
                    <i className={`zmdi zmdi-${query.icon}`}/>
                    <span>{query.queryName}</span>
                </span>
            </li>
        )
    }

    getMenuActions = () => {
        if (!this.props.menuActions || typeof(this.props.menuActions) === 'undefined' || this.props.menuActions === null || this.props.menuActions.totalCount === 0){
            return;
        }

        return this.props.menuActions.map((menuAction, index) => {
                if (menuAction.menuType === 'ACTION' && (menuAction.page === 'all' || menuAction.page === this.props.beans[this.props.app.appBody.id].currentPage) &&
                    hasFeatureAccess(this.props.datasources[this.props.beans[this.props.app.appBody.id].datasource], menuAction.keyValue) && menuAction.visible){
                    if (menuAction.elementType === 'HEADER'){
                        return  <li className="module-nav-label" key={'menuActionHeader'+menuAction.id}>
                                    {menuAction.description}
                                </li>
                    } else if (menuAction.elementType === 'FEATURE'){
                        return <li key={index+1} onClick={() => {this.onMenuActionClicked(menuAction.keyValue);}} key={'menuActionFeature'+menuAction.id}>
                                    <span className="jr-link">
                                        <i className={`zmdi zmdi-${menuAction.image}`}/>
                                        <span>{menuAction.description}</span>
                                    </span>
                                </li>
                    } else {
                        // TODO: separators or other options to be considered here
                    }
                }

                return;
            }
        )
    }

    onMenuActionClicked(menuActionKeyValue){
        const b2Event = {
            type: menuActionKeyValue,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id
            }
        }

        this.props.processB2Event(b2Event);
    }

    getActionMenuBody = () => {
        return (
            <div className="module-side">
                <div className="module-side-header">
                    <div className="module-logo">
                        <i className={"zmdi zmdi-"+this.props.app.attributes.icon.value+" mr-4"}/>
                        <span>{this.props.app.attributes.title.value}</span>
                    </div>
                </div>

                <div className="module-side-content">
                    <CustomScrollbars className="module-side-scroll scrollbar"
                              style={{height: this.props.width >= 1200 ? 'calc(100vh - 190px)' : 'calc(100vh - 80px)'}}>
                        {/* <div className="module-add-task">
                            <Button variant="contained" color="primary" className="btn-block"
                                onClick={() => {
                                    this.onCreateRecord();
                                }}>
                                <i className="zmdi zmdi-edit mr-3"/>
                                New
                            </Button>
                        </div>*/}

                        <ul className="module-nav">
                            <li className="module-nav-label">
                                <IntlMessages id="mail.filters"/>{/* TODO: use B2 internationalisation file*/}
                            </li>
                            <li key={0} onClick={() => {this.onQuery('All');}}>
                                <span className="jr-link">
                                    <i className="zmdi zmdi-archive"/>
                                    <span>All</span>
                                </span>
                            </li>
                            {this.getNavFilters()}
                            {this.getMenuActions()}
                        </ul>
                    </CustomScrollbars>
                </div>
            </div>
        );
    }

    render() {
        const menu = this.getActionMenuBody();
        return (
            <React.Fragment key={this.props.uiDefinition.id}>
                <div className="d-block d-xl-none">
                    <Drawer
                        open={this.props.drawerState}
                        onClose={this.onAppToggleDrawer.bind(this)}>
                        {menu}
                    </Drawer>
                </div>,
                <div className="app-module-sidenav d-none d-xl-flex">
                    {menu}
                </div>
            </React.Fragment>
        );
    };
}

export default B2ActionMenu;
