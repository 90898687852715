import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {DOWNLOAD_FORM} from 'constants/SafeeActionTypes';

class SafeeFormDownloadIconButton extends React.Component {
    onDownloadForm(){
        // event.stopPropagation();   // do not stop propagation to allow selectRecord of TableBody to execute

        const safeeEvent = {
            type: DOWNLOAD_FORM,
            eventDetails: {
                formType: this.props.datasource.entityList.meta.name,
                formId: this.props.datasource.entityList.selectedRecord.attributes.id.value,
                format: this.props.uiDefinition.format,
                longOperation: this.props.uiDefinition.longOperation,
                longOperationText: this.props.uiDefinition.longOperationText,
            }
        }

        this.props.processDownloadFormSafeeEvent(safeeEvent);
    }

    render() {
        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = undefined;
        }

        if (this.props.isHeader){
            // icon in header of table,
            // TODO: to be handled later if any
            return <React.Fragment key={this.props.uiDefinition.id}/>
        }else{
            // TODO: this.props.uiDefinition.onClick is null or something else, or
            // parent attribute is not null
            // we will handle this later

            return <IconButton type="button" className="icon-btn size-50 p-0" style={style} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                onClick={() => {
                   this.onDownloadForm();
                }}>
                   <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/>
                </IconButton>
        }
    }
}

export default SafeeFormDownloadIconButton;