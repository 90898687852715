import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';

class B2Tab extends React.Component {
    render() {
        return (
            <div key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} style={{padding: "15px "}} >
                {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
            </div>
        );
    }
}

export default B2Tab;