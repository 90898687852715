import React from 'react';
import moment from 'moment';

class B2Object extends React.Component {
    getValue(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return "";
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return "";
        }

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return;
            }

            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] ||
            this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] === null
        ){
            return "";
        }

        let value = null;

        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
            value = this.props.rowDatasource.attributes[targetAttribute].value;
        }else{
            value = this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].value;
        }

        if (value !== undefined && value !== null && value.constructor == Object){
            return JSON.stringify(value, null, 2);
        }

        let dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;

        if (dataType && dataType==='DATE' && value && ''!==value){
            if (this.props.uiDefinition.format){
                return moment(value).format(this.props.uiDefinition.format);
            }
        }
        return value;
    }

    getFileName(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return "";
        }

        let targetAttribute = this.props.uiDefinition.fileNameAttribute;

        if (!targetAttribute){
            return "";
        }

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return;
            }

            targetAttribute = this.props.uiDefinition.fileNameAttribute.substring(this.props.uiDefinition.fileNameAttribute.lastIndexOf(".")+1, this.props.uiDefinition.fileNameAttribute.length);
        }

        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] ||
            this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] === null
        ){
            return "";
        }

        let fileName = null;

        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
            fileName = this.props.rowDatasource.attributes[targetAttribute].value;
        }else{
            fileName = this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].value;
        }

        if (fileName !== undefined && fileName !== null && fileName.constructor == Object){
            return JSON.stringify(fileName, null, 2);
        }

        let dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;

        if (dataType && dataType==='DATE' && fileName && ''!==fileName){
            if (this.props.uiDefinition.format){
                return moment(fileName).format(this.props.uiDefinition.format);
            }
        }
        return fileName;
    }

    downloadPdf(event, data) {
        event.stopPropagation();
        const linkSource = data;
        const downloadLink = document.createElement("a");

        const fileName = this.getFileName();

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        downloadLink.remove();
     }

    render() {
        const value = this.getValue();

        if (value && ''!==value){
            let props = {};
            props.src = value;
            props.data = value;

            if (this.props.uiDefinition.width){
                props.width = this.props.uiDefinition.width
            }

            if (this.props.uiDefinition.height){
                props.height = this.props.uiDefinition.height
            }

            let style = null;
            if (this.props.uiDefinition.style){
                style = this.props.uiDefinition.style;
            }else{
                style = { "margin" : "10pt 0px"}
            }

            return (
                <div style={style}>
                    <object {...props}>
                        <h3>
                            If your browser doesn't open the pdf view, click this &nbsp;
                            <a
                                onClick={
                                    (event) => {
                                       this.downloadPdf(event, value);
                                    }
                                }
                                style={
                                    {
                                        color: "#3f51b5",
                                        textDecoration: "underline",
                                        backgroundColor: "transparent",
                                    }
                                }
                            >
                                link
                            </a>
                            &nbsp; to download the file
                        </h3>
                    </object>
                </div>
            );
        }else{
            const alt = this.props.uiDefinition.alt;

            if (alt && ''!==alt){
                return <div key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} style={{"textAlign": "center"}}>
                            <div dangerouslySetInnerHTML={{__html: alt}}/>
                       </div>
            }
        }

        return (<React.Fragment></React.Fragment>);
    }
}

export default B2Object;