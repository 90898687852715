import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

class B2TableDetails extends React.Component {
    render() {
        if (typeof(this.props.beans[this.props.bean.id].toggleTableDetails)!== 'undefined' &&
                  this.props.beans[this.props.bean.id].toggleTableDetails === "true" ){
            return <TableBody key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} style={{"backgroundColor": "#e2e2e2"}}>
                    <TableRow>
                         <TableCell style={this.props.uiDefinition.align?{textAlign: this.props.uiDefinition.align}:{}} >
                            <div>
                                {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                            </div>
                         </TableCell>
                    </TableRow>
                </TableBody>
        } else{
            return <React.Fragment key={this.props.uiDefinition.id}/>
        }
    }
}

export default B2TableDetails;