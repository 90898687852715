import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getOriginalSelectedRecord,getOriginalRecordById} from 'util/B2Utils';
import {ON_RECORDS_CHECKED, ON_RECORDS_UNCHECKED, ON_RECORD_ATTRIBUTE_UPDATED, ON_LOOKUP_RECORD_SELECTED, ON_QBE_SEARCH_PERFORMED} from 'constants/B2ActionTypes';

class B2Checkbox extends React.Component {
    doesPageHaveRecordsChecked = ()=>{
        if (!this.props.isHeader || typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords===null){
            return false;
        }

        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].attributes.id.value)) {
                return true;
            }
        }
        return false;
    }

    areAllRecordsInPageChecked = () =>{
        if (!this.props.datasource.entityList || this.props.datasource.entityList.totalCount === 0 || typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords===null){
            return false;
        }

        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (!this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].attributes.id.value)) {
                return false;
            }
        }

        return true;
    }

    isRecordChecked = () =>{
        if (typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords===null){
            return false;
        }

        return this.props.bean.checkedRecords.includes(this.props.rowDatasource.attributes.id.value);
    }

    onRecordCheckedUncheckedClick = (event) =>{

        event.stopPropagation();

    //        if (this.props.datasource.entityList.totalCount !== 0){
            if (typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords === null || !this.props.bean.checkedRecords.includes(this.props.rowDatasource.attributes.id.value)) {
                const b2Event = {
                    type: ON_RECORDS_CHECKED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        recordsIds: [this.props.rowDatasource.attributes.id.value]
                    }
                }
                this.props.processB2Event(b2Event);
            }else{
                const b2Event = {
                    type: ON_RECORDS_UNCHECKED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        recordsIds: [this.props.rowDatasource.attributes.id.value]
                    }
                }
                this.props.processB2Event(b2Event);
            }
    //       }
    }

    checkAllRecordsInPage = () => {
        if (this.props.datasource.entityList.totalCount === 0){
            return;
        }

        let recordsToCheck = [];
        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords === null || !this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].attributes.id.value)) {
                recordsToCheck.push(this.props.datasource.entityList.data[i].attributes.id.value);
            }
        }

        if (recordsToCheck.length >0){
            const b2Event = {
                type: ON_RECORDS_CHECKED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    recordsIds: recordsToCheck
                }
            }
            this.props.processB2Event(b2Event);
        }
    };

    uncheckAllRecordsInPage = () => {
        if (this.props.datasource.entityList.totalCount === 0 || typeof(this.props.bean.checkedRecords) === 'undefined' || this.props.bean.checkedRecords === null){
            return;
        }

        let recordsToUncheck = [];
        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        for(let i = startIndex ; i < endIndex; i++) {
            if (this.props.bean.checkedRecords.includes(this.props.datasource.entityList.data[i].attributes.id.value)) {
                recordsToUncheck.push(this.props.datasource.entityList.data[i].attributes.id.value);
            }
        }

        if (recordsToUncheck.length >0){
            const b2Event = {
                type: ON_RECORDS_UNCHECKED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    recordsIds: recordsToUncheck
                }
            }
            this.props.processB2Event(b2Event);
        }
    };

    onAllRecordsCheckedUncheckedClick = (event) => {
        event.stopPropagation();

        let startIndex = this.props.bean.currentPage * this.props.bean.recordsPerPage;
        let endIndex = (startIndex+this.props.bean.recordsPerPage)>this.props.datasource.entityList.totalCount?this.props.datasource.entityList.totalCount:(startIndex+this.props.bean.recordsPerPage);

        const recordsAllCheckedAlready = this.areAllRecordsInPageChecked();

        if (!recordsAllCheckedAlready) {
            this.checkAllRecordsInPage();
        } else {
            this.uncheckAllRecordsInPage();
        }
    }

    getValue(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return false;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return false;
        }

        if (targetAttribute.includes(".")){
            if (this.props.rowDatasource){
                return false;
            }

            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] ||
            this.props.datasource.entityList.selectedRecord.attributes[targetAttribute] === null
        ){
            return false;
        }

        let value = null;
        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
            value = this.props.rowDatasource.attributes[targetAttribute].value;
        }else{
            value = this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].value;
        }

        return value;
    }

    checkboxClicked = event => {
        event.stopPropagation();
        if (this.props.uiDefinition.onClick ===  ON_LOOKUP_RECORD_SELECTED){
            event.stopPropagation();
            const b2Event = {
                type: ON_LOOKUP_RECORD_SELECTED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    recordId: this.props.rowDatasource.attributes.id.value
                }
            }

            this.props.processB2Event(b2Event);
        }
    }

    fieldUpdated = event => {
        event.stopPropagation();

        if (this.props.rowDatasource){
            if (this.isReadOnly()){
                return false;
            }
            let record = getOriginalRecordById(this.props.datasource.id, this.props.rowDatasource.attributes.id.value);

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }
            if (
                !(
                    record.attributes[targetAttribute].value === null &&
                    event.target.value === null
                ) &&
                !(
                    record.attributes[targetAttribute].value === '' &&
                    event.target.value === null
                ) &&
                !(
                    record.attributes[targetAttribute].value === null &&
                    event.target.value === ''
                ) &&
                !(
                    record.attributes[targetAttribute].value === '' &&
                    event.target.value === ''
                )
            ){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        recordId: this.props.rowDatasource.attributes.id.value,
                        attribute: targetAttribute,
                        isPhantom: false,
                        value: event.target.checked
                    }
                }

                this.props.processB2Event(b2Event);
            }
        } else {
            if (this.isReadOnly()){
                return false;
            }
            let originalSelectedRecord = getOriginalSelectedRecord(this.props.datasource.id);

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }
            if (
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === null &&
                    event.target.value === null
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === '' &&
                    event.target.value === null
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === null &&
                    event.target.value === ''
                ) &&
                !(
                    originalSelectedRecord.attributes[targetAttribute].value === '' &&
                    event.target.value === ''
                )
            ){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        attribute: targetAttribute,
                        isPhantom: false,
                        value: event.target.checked
                    }
                }

                this.props.processB2Event(b2Event);
            }
        }
    };

    isDisabled(){
//        if (this.props.rowDatasource){
//            if (this.props.readOnlyTable){
//                return true;
//            }
//            return false;
//        }

        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return true;
        }

        return false;
    }

    getLabel(){
        if (this.props.rowDatasource){
            return '';
        }

        if (this.props.uiDefinition.label){
            return this.props.uiDefinition.label;
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return;
        }

        if (targetAttribute.includes(".")){
            targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
        }

        if (this.props.datasource.entityList && this.props.datasource.entityList.meta && typeof (this.props.datasource.entityList.meta.attributes[targetAttribute])!='undefined' && this.props.datasource.entityList.meta.attributes[targetAttribute].title){
            return this.props.datasource.entityList.meta.attributes[targetAttribute].title;
        } else {
            return targetAttribute;
        }
    }

    isReadOnly(){
        if (
                (
                    this.props.uiDefinition.readOnly &&
                    this.props.uiDefinition.readOnly==="true"
                ) ||
                (
                    this.props.uiDefinition.readonly &&
                    this.props.uiDefinition.readonly==="true"
                ) ||
                this.props.readOnlyTable
        ){
            return true;
        }

        if (this.props.lookup){
            return true;
        }

        if (this.props.rowDatasource){

            if (this.props.rowDatasource.isReadOnly && this.props.rowDatasource.isReadOnly==="true"){
                return true;
            }

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return false;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }

            if (!this.props.rowDatasource.attributes[targetAttribute]){
                return false;
            }

            if (this.props.rowDatasource.attributes[targetAttribute].isReadOnly &&
                this.props.rowDatasource.attributes[targetAttribute].isReadOnly === "true"){
                return true;
            }

            return false;
        } else {

            if (!this.props.datasource.entityList.selectedRecord){
                return true;
            }

            if (this.props.datasource.entityList.selectedRecord.isReadOnly && this.props.datasource.entityList.selectedRecord.isReadOnly==="true"){
                return true;
            }

            let targetAttribute = this.props.uiDefinition.attribute;

            if (!targetAttribute){
                return false;
            }

            if (targetAttribute.includes(".")){
                targetAttribute = this.props.uiDefinition.attribute.substring(this.props.uiDefinition.attribute.lastIndexOf(".")+1, this.props.uiDefinition.attribute.length);
            }

            if (!this.props.datasource.entityList.selectedRecord.attributes[targetAttribute]){
                return true;
            }

            if (this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].isReadOnly &&
                this.props.datasource.entityList.selectedRecord.attributes[targetAttribute].isReadOnly === "true"){
                return true;
            }

            return false;
        }
    }

    getFilterValue(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.phantomEntity){
            return "";
        }

        let targetAttribute = this.props.uiDefinition.attribute;

        if (!targetAttribute){
            return "";
        }

        if (targetAttribute.includes(".")){
            return null;
        }

        if (typeof(this.props.datasource.entityList.phantomEntity.attributes[targetAttribute]) === 'undefined' ||
            !this.props.datasource.entityList.phantomEntity.attributes[targetAttribute] ||
            this.props.datasource.entityList.phantomEntity.attributes[targetAttribute] === null
        ){
            return "";
        }

//        let dataType = this.props.datasource.entityList.meta.attributes[targetAttribute].type;

//        if (dataType && (dataType==='DATE' || dataType==='DATETIME') && this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value && ''!==this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value){
//            //if (this.props.uiDefinition.format){
//                return moment(this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value).format(this.props.uiDefinition.format);
//            //}
//        }

        return this.props.datasource.entityList.phantomEntity.attributes[targetAttribute].value;
    }

    isFilterDisabled(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.phantomEntity){
            return true;
        }

        return false;
    }

    dateFilterFieldUpdated = event => {

        let targetAttribute = this.props.uiDefinition.attribute;

        if (event != null){

            let filterRecord = this.props.datasource.entityList.phantomEntity;

            if (!targetAttribute){
                return;
            }

            if (targetAttribute.includes(".")){
                return;
            }

            const b2Event = {
                type: ON_QBE_SEARCH_PERFORMED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    value: (event.target.checked)?true:null
                }
            }

            this.props.processB2Event(b2Event);
        }else{
            const b2Event = {
                type: ON_QBE_SEARCH_PERFORMED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    attribute: targetAttribute,
                    value: null
                }
            }

            this.props.processB2Event(b2Event);
        }
    };

    render() {
        let style = null;
        if (this.props.style){
            style = this.props.style;
        } else if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = {}
        }

        if (this.props.uiDefinition.onChange==='checkRecord' || this.props.uiDefinition.onClick==='checkRecord'){
            if (this.props.isHeader && !this.props.isFilter){
                return <Checkbox color="primary"
                            indeterminate={this.doesPageHaveRecordsChecked() && !this.areAllRecordsInPageChecked()}
                            checked={this.areAllRecordsInPageChecked()}
                            onChange={this.onAllRecordsCheckedUncheckedClick.bind(this)}
                            value="SelectRecords"
                            style={style}/>

            } else if (!this.props.isHeader && this.props.isFilter){
                return <React.Fragment></React.Fragment>
            } else if (this.props.rowDatasource) {
                return <Checkbox   color="primary"
                            checked={this.isRecordChecked()}
                            onClick={this.onRecordCheckedUncheckedClick.bind(this)}
                            style={style}
                            />
            } else {
                return <React.Fragment key={this.props.uiDefinition.id}></React.Fragment> // TODO: enable checkbox to appear within table rows
            }
        }else if (!this.props.isHeader && !this.props.isFilter){
            const value = this.getValue();
            const isReadOnly = this.isReadOnly();
            const isDisabled = this.isDisabled();
            const checked = (value)?(value === true):false;
             return <FormGroup key={this.props.uiDefinition.id} className={"MuiFormControl-marginNormal"} style={{ "margin" : "5px"}}>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary"
                                    inputProps={
                                        {
                                            readOnly: isReadOnly
                                        }
                                    }
                                    style={{"margin": "0px", "padding": "0px"}}
                                    disabled={isDisabled}
                                    checked={checked}
                                    onChange={(e) => this.fieldUpdated(e)}
                                    onClick={(e) => this.checkboxClicked(e)}
                                    value={(value)?value:false}
                                    style={style}
                                />
                            }
                            label={this.getLabel()}
                        />
                    </FormGroup>
        }else if (!this.props.isHeader && this.props.isFilter){
            const value = this.getFilterValue();
            const disabled = this.isFilterDisabled();

            return <Checkbox color="default"
                    indeterminate={this.doesPageHaveRecordsChecked() && !this.areAllRecordsInPageChecked()}
                    checked={value?value:false}
                    onChange={(e) => this.dateFilterFieldUpdated(e)}
                    value="Filter"
                    style={{
                       "padding": "0px 0px 0px 3px",
                       "margin": "0px"
                     }}/>
        }
    }
}

export default B2Checkbox;