import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getOriginalSelectedRecord} from 'util/B2Utils';
import {ON_SELECT_OPENED, ON_RECORD_ATTRIBUTE_UPDATED} from 'constants/B2ActionTypes';

class B2Select extends React.Component {
    onSelectOpen = event => {
        event.stopPropagation();
        const b2Event = {
            type: ON_SELECT_OPENED,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id,
                elementId: this.props.uiDefinition.id,
                attribute: this.props.uiDefinition.attribute
            }
        }

        this.props.processB2Event(b2Event);
    }

    getValue(){
        if (!this.props.datasource.entityList.selectedRecord){
            return "";
        }

        if (typeof(this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute]) === 'undefined' ||
            !this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute] ||
            this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute].value === null
        ){
            return "";
        }

        return this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute].value;
    }

    fieldUpdated = (event, option) => {
        event.stopPropagation();
        const originalSelectedRecord = getOriginalSelectedRecord(this.props.datasource.id);

        if (originalSelectedRecord.attributes[this.props.uiDefinition.attribute].value !== option.value){
            const b2Event = {
                type: ON_RECORD_ATTRIBUTE_UPDATED,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    lookupDatasourceId: this.props.uiDefinition.id,
                    attribute: this.props.uiDefinition.attribute,
                    lookupEntityId: option.props.lookupid
                }
            }

            this.props.processB2Event(b2Event);
        } else {
            let sourceAttribute = this.props.datasources[this.props.uiDefinition.id].lookupMap.attributes.sourceAttribute.value;

            let numberOfLookupsWithSameValue = 0;
            let index;

            for (index=0; index<this.props.datasources[this.props.uiDefinition.id].entityList.data.length; index++){
                if (this.props.datasources[this.props.uiDefinition.id].entityList.data[index].attribute[sourceAttribute].value === option.value){
                    numberOfLookupsWithSameValue++;
                }
            }

            if (numberOfLookupsWithSameValue>1){
                const b2Event = {
                    type: ON_RECORD_ATTRIBUTE_UPDATED,
                    eventDetails: {
                        beanId: this.props.bean.id,
                        datasourceId: this.props.datasource.id,
                        lookupDatasourceId: this.props.uiDefinition.id,
                        attribute: this.props.uiDefinition.attribute,
                        lookupEntityId: option.props.lookupid
                    }
                }
                this.props.processB2Event(b2Event);
            }
        }
    };

    getLabel(){
        if (this.props.uiDefinition.label){
            return this.props.uiDefinition.label;
        }else if (typeof (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute])!='undefined' && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].title){
            return this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].title;
        } else {
            return this.props.uiDefinition.attribute;
        }
    }

    isReadOnly(){
        if (this.props.uiDefinition.readonly && this.props.uiDefinition.readonly==="true"){
            return true;
        }

        if (!this.props.datasource.entityList.selectedRecord){
            return true;
        }

        if (this.props.datasource.entityList.selectedRecord.isReadOnly && this.props.datasource.entityList.selectedRecord.isReadOnly==="true"){
            return true;
        }

        if (!this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute]){
            return true;
        }

        if (this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute].isReadOnly &&
            this.props.datasource.entityList.selectedRecord.attributes[this.props.uiDefinition.attribute].isReadOnly === "true"){
            return true;
        }

        return false;
    }

    getSelectItems(){
        const lookupDatasourceId = this.props.uiDefinition.id+'_Lookup';
        if (typeof(this.props.datasources[lookupDatasourceId]) !== 'undefined' &&
            this.props.datasources[lookupDatasourceId] !== null &&
            typeof(this.props.datasources[lookupDatasourceId].lookupMap) !== 'undefined' &&
            this.props.datasources[lookupDatasourceId].lookupMap !== null &&
            typeof(this.props.datasources[lookupDatasourceId].lookupMap.attributes.sourceAttribute) !== 'undefined' &&
            this.props.datasources[lookupDatasourceId].lookupMap.attributes.sourceAttribute !== null &&
            this.props.datasources[lookupDatasourceId].lookupMap.attributes.sourceAttribute !== '' &&
            typeof(this.props.datasources[lookupDatasourceId].entityList.meta.attributes[this.props.datasources[lookupDatasourceId].lookupMap.attributes.sourceAttribute.value]) !== 'undefined'
            ){
            let sourceAttribute = this.props.datasources[lookupDatasourceId].lookupMap.attributes.sourceAttribute.value;

            return this.props.datasources[lookupDatasourceId].entityList.data.map((lookupEntity, index) =>
                            <MenuItem lookupid={lookupEntity.attributes.id.value} value={lookupEntity.attributes[sourceAttribute].value} key={lookupEntity.attributes.id.value+'-'+lookupEntity.attributes[sourceAttribute].value}>{lookupEntity.attributes[sourceAttribute].value}</MenuItem>
                        );
        }

        return <MenuItem lookupid='' value={this.getValue()}>{this.getValue()}</MenuItem>
    }

    isDisabled(){
            if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
                return true;
            }

            return false;
        }

    isRequired(){
        if (typeof(this.props.uiDefinition.required) !== 'undefined' && this.props.uiDefinition.required === 'true'){
            return true;
        }

        if (typeof(this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute]) !== 'undefined' &&
            typeof(this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].isRequired) !== 'undefined' &&
            this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].isRequired === 'true'
        ){
            return true;
        }

        return false;
    }
    render() {
        if (this.props.isHeader){
             return <React.Fragment key={this.props.uiDefinition.id}/>; // TODO: to be handled later, or simply not supported
        }else{
            //<Tooltip title={this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].description} >
            //  <div>
            const readOnly = this.isReadOnly();
            const disabled = this.isDisabled();
            let required = false;
            if (!disabled && !readOnly){
                required = this.isRequired();
            }

            let style = {}
            if (disabled){
                style = {
                    "backgroundColor": "rgba(0, 0, 0, 0.12)"
                }
            }else if (readOnly){
                style = {
                    "backgroundColor": "rgba(0, 0, 0, 0.09)"
                }
            }

            return <FormControl
                        margin="dense"
                        variant="outlined"
                        key={this.props.uiDefinition.key}
                        required={required}
                        disabled={disabled}
                        style={style}
                        size="small"
                        fullWidth
                        autoComplete='new-password'
                        >
                        <InputLabel>{this.getLabel()}</InputLabel>
                        <Select
                            onOpen={this.onSelectOpen}
                            value={this.getValue()}
                            onChange={(event, option) => {this.fieldUpdated(event, option)}}
                            label={this.getLabel()}
                            inputProps={{ readOnly: this.isReadOnly() }}
                        >
                            {this.getSelectItems()}
                        </Select>
                    </FormControl>
            //</div>
            //</Tooltip>
        }
    }
}

export default B2Select;