import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

//import {
//    hideMessage,
//    showAuthLoader,
//    userFacebookSignIn,
//    userGithubSignIn,
//    userGoogleSignIn,
//    userSignIn,
//    userTwitterSignIn
//} from 'actions/Auth';

import {
    SIGNIN_USER,
    CLOSE_B2_MESSAGE
} from 'constants/B2ActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            showPassword: false
        }
    }

    togglePasswordVisibility = (e, value) => {
        this.setState({
            ...this.state,
            showPassword: value
        });
    }

    userSignIn = () => {
        const b2SecEvent = {
            type: SIGNIN_USER,
            eventDetails: {
                loginName: this.state.email,
                password: this.state.password
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    closeB2Message = () => {
        const b2SecEvent = {
            type: CLOSE_B2_MESSAGE,
            eventDetails: {}
        }
        this.props.processB2SecurityEvent(b2SecEvent);
    }

    componentDidUpdate() {
        if (this.props.b2Message) {
            setTimeout(() => {
                this.closeB2Message();
            }, 100);
        }
        if (this.props.userToken !== null) {
            this.props.history.push('/');
        }
    }

    render() {
        const {
            email,
            password
        } = this.state;

        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });

        let tokenQueryParam = params['x-auth-token'];

        if (tokenQueryParam && ''!==tokenQueryParam){
            return (
                <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                </div>
            );
        }

        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Safee">
                            <img src={require("assets/images/safee_logo_square.png")} /*style={{"width":"200px", "height":"145px"}}*/ alt="Safee" title="Safee"/>
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.signIn"/></h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        label={<IntlMessages id="appModule.email"/>}
                                        fullWidth
                                        onChange={(event) => this.setState({email: event.target.value})}
                                        defaultValue={email}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />
                                    <TextField
                                        type={this.state.showPassword?undefined:'password'}
                                        label={<IntlMessages id="appModule.password"/>}
                                        fullWidth
                                        onChange={(event) => this.setState({password: event.target.value})}
                                        defaultValue={password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"

                                        InputProps={{
                                            endAdornment: (this.state.showPassword)? (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="Hide Password"
                                                    onClick={(e) => this.togglePasswordVisibility(e,false)}
                                                    edge="end"
                                                  >
                                                    <i className="zmdi zmdi-eye" title="Hide Password"/>
                                                  </IconButton>
                                                </InputAdornment>
                                              ):
                                              (
                                                <InputAdornment position="end">
                                                  <IconButton
                                                    aria-label="Show Password"
                                                    onClick={(e) => this.togglePasswordVisibility(e,true)}
                                                    edge="end"
                                                  >
                                                    <i className="zmdi zmdi-eye-off" title="Show Password"/>
                                                  </IconButton>
                                                </InputAdornment>
                                              ),
                                            autoComplete:'off',
                                            form: {
                                                autocomplete: 'off',
                                            }
                                        }}
                                    />

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button style={{width: '6rem'}} onClick={() => {
                                                this.userSignIn();
                                            }
                                        } variant="contained" color="primary" >
                                            <IntlMessages id="appModule.signIn"/>
                                        </Button>

                                        <div className="d-flex align-items-centre justify-content-between">
                                            <Link className="ml-3" to="/signup">
                                                <IntlMessages id="signIn.signUp"/>
                                            </Link>

                                            <Link className="ml-2" to="/forgotpassword">
                                                <IntlMessages id="signIn.forgotPassword"/>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="app-social-block my-1 my-sm-3">
                                        <IntlMessages id="signIn.connectWith"/>
                                        <ul className="social-link">
                                            <li>
                                                <Link to={{ pathname: "https://www.facebook.com/profile.php?id=100065027493567" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            window.location.path = 'https://www.facebook.com/profile.php?id=100065027493567';
                                                        }}>
                                                        <i className="zmdi zmdi-facebook"/>
                                                    </IconButton>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to={{ pathname: "https://www.linkedin.com/company/safeeelogbook/" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            window.location.path = 'https://www.linkedin.com/company/safeeelogbook/';
                                                        }}>
                                                        <i className="zmdi zmdi-linkedin"/>
                                                    </IconButton>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to={{ pathname: "https://twitter.com/SafeeAU" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            window.location.path = 'https://twitter.com/SafeeAU';
                                                        }}>

                                                        <i className="zmdi zmdi-twitter"/>
                                                    </IconButton>
                                                </Link>
                                            </li>

                                            <li>
                                                <Link to={{ pathname: "https://www.youtube.com/@SafeeELogbook/playlists" }} target="_blank">
                                                    <IconButton className="icon"
                                                        onClick={() => {
                                                            window.location.path = 'https://www.youtube.com/@SafeeELogbook/playlists';
                                                        }}>
                                                        <i className="zmdi zmdi-youtube-play"/>
                                                    </IconButton>
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.props.loginLoader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {this.props.b2Message && NotificationManager.error(this.props.b2Message.message)}
                <NotificationContainer/>
            </div>
        );
    }
}

const mapStateToProps = ({b2State}) => {
    const {loginLoader, b2Message, userToken} = b2State;
    return {loginLoader, b2Message, userToken}
};

export default connect(mapStateToProps, {
    processB2SecurityEvent
})(SignIn);
/*
export default connect(mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGoogleSignIn,
    userGithubSignIn,
    userTwitterSignIn
})(SignIn);
*/