import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';

class B2Page extends React.Component {
    render() {
        if (
            this.props.app.attributes.type.value === 'Simple' ||
            (
                (
                    this.props.uiDefinition.type==='list' &&
                    (this.props.beans[this.props.app.appBody.id].currentPage===null || this.props.beans[this.props.app.appBody.id].currentPage==='list')
                ) ||
                (this.props.uiDefinition.type==='main' && this.props.beans[this.props.app.appBody.id].currentPage==='main')
            )
        ){
            if (this.props.app.attributes.type.value === 'Advanced'){
                return (<div className="module-box" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                            <div className="module-box-content" style={{"backgroundColor": ''}}>
                                <div >
                                    {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                                </div>
                            </div>
                        </div>
                );
            } else {
                return (<React.Fragment>
                            {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                        </React.Fragment>
                        );
            }
        }
        return <React.Fragment key={this.props.uiDefinition.id}/> // if page type is not defined, or not main or list, it is not supported
                    // TODO: we may change this behavior to always display so developer knows there is an issue in application definition
    }
}

export default B2Page;