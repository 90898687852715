import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

import {
    SIGNOUT_USER
} from 'constants/B2ActionTypes';

import {
    RESEND_ACTIVE_LINK
} from 'constants/SafeeActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';


class UserInfo extends React.Component {

    state = {
        anchorEl: null,
        open: false,
    };

    userSignOut = () => {
        const b2SecEvent = {
            type: SIGNOUT_USER,
            eventDetails: {
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    resendActivationLink = () => {
        const b2SecEvent = {
            type: RESEND_ACTIVE_LINK,
            eventDetails: {
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    handleClick = event => {
        this.setState({open: true, anchorEl: event.currentTarget});
    };

    handleRequestClose = () => {
        this.setState({open: false});
    };

    isAppAccessible(appName) {
        if (this.props.accessibleApps && this.props.accessibleApps.length>0){
            for (let i = 0; i < this.props.accessibleApps.length; i++) {
                if (this.props.accessibleApps[i].attributes.appName.value === appName){
                    return true;
                }
            }
        }

        return false;
    }


    getHomeURL(){
        let url = '/'
        if (this.props.accessibleApps && this.props.accessibleApps.length > 0){
            let accessibleHomeApp = this.props.accessibleApps.find((element) => {
                return element.attributes.appName &&
                    element.attributes.appName.value &&
                    element.attributes.appName.value !== null &&
                    typeof element.attributes.appName.value != 'undefined' &&
                    element.attributes.appName.value.toLowerCase() === 'home';
              });

            if (!accessibleHomeApp){
                if (this.props.defaultApp){
                    url =  '/app/b2/' + this.props.defaultApp.toLowerCase();
                }else if (this.props.dashboards && this.props.dashboards.length > 0 ){
                    let defaultDashboard = this.props.dashboards.find((element) => {
                        return element.attributes.elementType &&
                            element.attributes.elementType.value === 'APP' &&
                            element.attributes.menuType &&
                            element.attributes.menuType.value === 'DASHBOARD' &&
                            element.attributes.appName &&
                            element.attributes.appName.value &&
                            element.attributes.appName.value !== null &&
                            typeof element.attributes.appName.value !== 'undefined' &&
                            element.attributes.appName.value.toLowerCase() !== 'home';
                      });
                    if (defaultDashboard){
                        url =  '/app/b2/' + defaultDashboard.attributes.appName.value.toLowerCase();
                    }else if (this.props.dashboards[0].attributes.appName.value){
                        url =  '/app/b2/' + this.props.dashboards[0].attributes.appName.value.toLowerCase();
                    }
                }
            }
        }

        return url;
    }

    render() {
        return (
            <div className="user-profile d-flex flex-row align-items-center">
                <Button>

                    <i className={'zmdi zmdi-account zmdi-hc-2x mdc-text-light-blue' } style={{color: 'white'}} />

                  </Button>
               {/* <Avatar
                    alt='...'
                    src={'https://via.placeholder.com/150x150'}
                    className="user-avatar "
                />*/}
                <div className="user-detail">
                    <h4 className="user-name" onClick={this.handleClick}>{(this.props.user)?(
                         ((this.props.user.attributes.firstName.value == null)?'': this.props.user.attributes.firstName.value+' ') +
                          ((this.props.user.attributes.lastName.value == null)?'': this.props.user.attributes.lastName.value+' ')):
                          ''
                     }<i
                        className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                    </h4>
                </div>
                <Menu className="user-info"
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleRequestClose}
                    PaperProps={{
                        style: {
                            minWidth: 120,
                            paddingTop: 0,
                            paddingBottom: 0
                        }
                    }}
                >
                    <MenuItem onClick={this.handleRequestClose}>
                        <Link className="jr-list-link" to={this.getHomeURL()}> {/* TODO: add favourite apps*/}
                            <i className="zmdi zmdi-home zmdi-hc-fw mr-2"/>
                            <IntlMessages id="popup.home"/>
                        </Link>
                    </MenuItem>

                    {
                        this.isAppAccessible('Subscription') && <MenuItem onClick={this.handleRequestClose}>
                            <Link className="jr-list-link" to="/app/b2/subscription"> {/* TODO: add favourite apps*/}
                                <i className="zmdi zmdi-card-membership zmdi-hc-fw mr-2"/>
                                <IntlMessages id="safee.subscription"/>
                            </Link>
                        </MenuItem>
                    }

                    {
                        this.isAppAccessible('UserProfile') && <MenuItem onClick={this.handleRequestClose}>
                            <Link className="jr-list-link" to="/app/b2/userprofile"> {/* TODO: add favourite apps*/}
                                <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2"/>
                                <IntlMessages id="popup.profile"/>
                            </Link>
                        </MenuItem>
                    }
                    {/*
                    <MenuItem onClick={this.handleRequestClose}>
                        <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                        <IntlMessages id="popup.setting"/>
                    </MenuItem>
                    */}
                    {
                        (this.props.user && this.props.user.attributes.status.value==='New') &&
                        <MenuItem onClick={() => {
                            this.handleRequestClose();
                            this.resendActivationLink()
                        }}>
                            <i className="zmdi zmdi zmdi-link zmdi-hc-fw mr-1"/>
                            <IntlMessages id="safee.resendActivationLink"/>
                        </MenuItem>
                    }

                    <MenuItem onClick={() => {
                        this.handleRequestClose();
                        this.userSignOut()
                    }}>
                        <i className="zmdi zmdi-run zmdi-hc-fw mr-2"/>

                        <IntlMessages id="popup.logout" />
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = ({b2State, settings}) => {
    const {locale} = settings;
    const {user, accessibleApps, dashboards} = b2State;
    return {locale, accessibleApps, dashboards, user}
};
export default connect(mapStateToProps, {processB2SecurityEvent})(UserInfo);


