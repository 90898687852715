import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {ON_TOGGLE_BOOKMARK_RECORD, TOGGLE_TABLE_DETAILS, DOWNLOAD_ATTACHMENT} from 'constants/B2ActionTypes';

class B2IconButton extends React.Component {
    onBookmarkSelect(event,record){
        event.stopPropagation();
        const b2Event = {
            type: ON_TOGGLE_BOOKMARK_RECORD,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: record.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    onToggleTableDetails(event,record){
        event.stopPropagation();
        const b2Event = {
            type: TOGGLE_TABLE_DETAILS,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: record.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    sendEvent = () => {
        if (this.props.uiDefinition.onClick){
            const b2Event = {
                type: this.props.uiDefinition.onClick,
                eventDetails: {
                    beanId: this.props.bean.id,
                    datasourceId: this.props.datasource.id,
                    longOperation: this.props.uiDefinition.longOperation,
                    longOperationText: this.props.uiDefinition.longOperationText
                }
            }

            this.props.processB2Event(b2Event);
        }
    }

    onDownloadAttachment(event,record){
        event.stopPropagation();
        const b2Event = {
            type: DOWNLOAD_ATTACHMENT,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: record.attributes.id.value,
                longOperation: this.props.uiDefinition.longOperation,
                longOperationText: this.props.uiDefinition.longOperationText
            }
        }

        this.props.processDownloadAttachmentB2Event(b2Event);
    }

    onDownloadAttachmentByAttachmentId(event,attachmentId){
        event.stopPropagation();
        const b2Event = {
            type: DOWNLOAD_ATTACHMENT,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: attachmentId,
                longOperation: this.props.uiDefinition.longOperation,
                longOperationText: this.props.uiDefinition.longOperationText
            }
        }

        this.props.processDownloadAttachmentB2Event(b2Event);
    }

    render() {
        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = undefined;
        }

        if (typeof(this.props.parentUiDefinition.attribute)==='undefined' &&
            typeof(this.props.uiDefinition.onClick)!=='undefined' &&
            this.props.uiDefinition.onClick==='bookmarkRecord'){
            if (!this.props.isHeader){
                // applies to a single record, usually for table row
                return <IconButton type="button" className="icon-btn size-50 p-0" style={style} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                           onClick={(event) => {
                              this.onBookmarkSelect(event, this.props.rowDatasource);
                           }}>
                           {(typeof(this.props.beans[this.props.app.appBody.id].bookmarkedRecords)!== 'undefined' &&
                                this.props.beans[this.props.app.appBody.id].bookmarkedRecords.includes(this.props.rowDatasource.attributes.id.value)) ?
                               <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/> :
                               <i className={'zmdi zmdi-'+this.props.uiDefinition.inactiveIcon}/>
                           }
                       </IconButton>
            }else{
                // icon in header of table,
                // TODO: to be handled later if any
                return <React.Fragment key={this.props.uiDefinition.id}/>
            }
        } else if (typeof(this.props.parentUiDefinition.attribute)==='undefined' &&
            typeof(this.props.uiDefinition.onClick)!=='undefined' &&
            this.props.uiDefinition.onClick==='toggleTableDetails'){

            if (!this.props.isHeader){
                // applies to a single record, usually for table row
                return <IconButton type="button" className="icon-btn size-50 p-0" style={style} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                         onClick={(event) => {
                            this.onToggleTableDetails(event, this.props.rowDatasource);
                         }}>
                         {(typeof(this.props.beans[this.props.bean.id].toggleTableDetails)!== 'undefined' &&
                              this.props.beans[this.props.bean.id].toggleTableDetails === "true" &&
                              this.props.rowDatasource.attributes.id.value === this.props.datasource.entityList.selectedRecord.attributes.id.value) ?
                             <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/> :
                             <i className={'zmdi zmdi-'+this.props.uiDefinition.inactiveIcon}/>
                         }
                     </IconButton>
            }else{
                // icon in header of table,
                // TODO: to be handled later if any
                return <React.Fragment key={this.props.uiDefinition.id}/>
            }
        }else if (this.props.isHeader){
            // icon in header of table,
            // TODO: to be handled later if any
            return <React.Fragment key={this.props.uiDefinition.id}/>
        }else  if (typeof(this.props.parentUiDefinition.attribute)==='undefined' &&
             typeof(this.props.uiDefinition.onClick)!=='undefined' &&
             this.props.uiDefinition.onClick==='downloadAttachment'){
             let attachmentId = this.props.uiDefinition.attachmentId;
             if (attachmentId){
                return <IconButton type="button" className="icon-btn size-50 p-0" style={style} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                          onClick={(event) => {
                             this.onDownloadAttachmentByAttachmentId(event, attachmentId);
                          }}>

                      <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/>
                      </IconButton>
             }else{

                 if (!this.props.isHeader){
                     // applies to a single record, usually for table row
                     return <IconButton type="button" className="icon-btn size-50 p-0" style={style} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                              onClick={(event) => {
                                 this.onDownloadAttachment(event, this.props.rowDatasource);
                              }}>

                          <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/>
                          </IconButton>
                 }else{
                     // icon in header of table,
                     // TODO: to be handled later if any
                     return <React.Fragment key={this.props.uiDefinition.id}/>
                 }
             }
        } else{
            // TODO: this.props.uiDefinition.onClick is null or something else, or
            // parent attribute is not null
            // we will handle this later

            return <IconButton type="button" className="icon-btn size-50 p-0" style={style} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}
                onClick={() => {
                   this.sendEvent();
                }}>
                   <i className={'zmdi zmdi-'+this.props.uiDefinition.activeIcon}/>
                </IconButton>
        }

        return <React.Fragment key={this.props.uiDefinition.id}/>;
    }
}

export default B2IconButton;