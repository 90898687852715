import React from 'react';
import {Link} from 'react-router-dom';

class SafeeFullNameLabel extends React.Component {
//    showRecordDetails(event){
//        event.stopPropagation();
//        const b2Event = {
//            type: SHOW_RECORD_DETAILS,
//            eventDetails: {
//                beanId: this.props.bean.id,
//                recordId: this.props.rowDatasource.attributes.id.value
//            }
//        }
//
//        this.props.processB2Event(b2Event);
//    }

    getActualEntityList(attribute){

        if (!attribute.includes(".")){
            return this.props.rowDatasourc;
        }else{
            let str_array = attribute.split('.');
            let entityObj = this.props.rowDatasource;

            for(var i = 0; i < str_array.length - 1; i++) {
                if (entityObj.relationships[str_array[i]] == null){
                    return null;
                }else{

                    entityObj = entityObj.relationships[str_array[i]].selectedRecord;
                }
            }

            return entityObj;
        }
    }

    getValue(attribute){
        //let attribute = this.props.uiDefinition.attribute;

        let value = null;

        if (attribute.includes(".")){

            let datasource = this.getActualEntityList(attribute);

            if (datasource){
                let attributes = attribute.split(".");

                value = datasource.attributes[attributes[attributes.length-1]].value;
            }else{
                return "";
            }
        }else{
            if (this.props.rowDatasource){
                value = this.props.rowDatasource.attributes[attribute].value;
            }else{
                return "";
            }
        }

        if (value !== undefined && value !== null && value.constructor == Object){
            return JSON.stringify(value, null, 2);
        }

        return value;

    }

    getFullName(){

        let firstName = this.getValue(this.props.uiDefinition.firstNameAttribute);
        let lastName = this.getValue(this.props.uiDefinition.lastNameAttribute);

        if (!firstName){
            if (!lastName){
                return lastName.trim();
            }else{
                return "";
            }
        }else{
            if (!lastName){
                return firstName.trim();
            }else{
                return firstName.trim() + " " + lastName.trim();
            }
        }
    }

    render() {

        if (this.props.isHeader || this.props.isFilter){
            return null;
        }

        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = undefined;
        }

        let className = null;
        if (this.props.uiDefinition.class){
            className = this.props.uiDefinition.class;
        }else{
            className = undefined;
        }

        let target = this.props.uiDefinition.target;

        let hyperlink = this.props.uiDefinition.hyperlink;
        let valueToReplace = hyperlink.substring(
                      hyperlink.indexOf("$") + 1,
                      hyperlink.lastIndexOf("$")
                  );
        let value = this.getValue(valueToReplace);
        hyperlink = hyperlink.replace('$'+valueToReplace+'$', value);

        if (hyperlink.startsWith("http")){
            return <a onClick={(event) => event.stopPropagation()} href={hyperlink} target={target} className={className} style={(style!=null && style !== "")?style:{}}>{this.getFullName()}</a>
        }else{
            return <Link onClick={(event) => event.stopPropagation()} to={hyperlink} target={target} className={className} style={(style!=null && style !== "")?style:{}}>{this.getFullName()}</Link>
        }
    }
}

export default SafeeFullNameLabel;