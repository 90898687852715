import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import queryString from 'query-string';
import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignUp,
    userTwitterSignIn
} from 'actions/B2Auth';

import {
    RESET_PASSWORD,
    CLOSE_B2_MESSAGE
} from 'constants/B2ActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';
import {stringify} from "@amcharts/amcharts4/.internal/core/utils/Utils";

class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            password:'',
            passwordConfirmation:''
        }
    }

    resetPassword = () => {
        const b2SecEvent = {
            type: RESET_PASSWORD,
            eventDetails: {
                password: this.state.password,
                passwordConfirmation: this.state.passwordConfirmation,
                token: queryString.parse(this.props.location.search)['token']
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    closeB2Message = () => {
        const b2SecEvent = {
            type: CLOSE_B2_MESSAGE,
            eventDetails: {}
        }
        this.props.processB2SecurityEvent(b2SecEvent);
    }

    componentDidUpdate() {
        if (this.props.b2Message) {
            setTimeout(() => {
                this.closeB2Message();
            }, 100);
        }
    }

    render() {
        const {
            password,
            passwordConfirmation,
            token
        } = this.state;

        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Safee">
                            <img src={require("assets/images/safee_logo_square.png")} alt="Safee" title="Safee"/>
                        </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header">
                            <h1>Password Reset</h1>
                        </div>

                        <div className="app-login-form">
                            <form method="post" action="/">
                                <TextField
                                    type="password"
                                    label="New Password"
                                    onChange={(event) => this.setState({password: event.target.value})}
                                    fullWidth
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="password"
                                    label="Retype New Password"
                                    onChange={(event) => this.setState({passwordConfirmation: event.target.value})}
                                    fullWidth
                                    defaultValue={passwordConfirmation}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                {/* <TextField
                                  type="text"
                                  label="Name"
                                  onChange={(event) => this.setState({name: event.target.value})}
                                  fullWidth
                                  defaultValue={name}
                                  margin="normal"
                                  className="mt-0 mb-2"
                                /> */}
                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button variant="contained" onClick={this.resetPassword} color="primary">
                                        <IntlMessages id="appModule.resetPassword"/>
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.props.loginLoader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {this.props.b2Message && this.props.b2Message.type==='info' && NotificationManager.info(this.props.b2Message.message)}
                {this.props.b2Message && this.props.b2Message.type==='error' && NotificationManager.error(this.props.b2Message.message)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = ({b2State}) => {
    const {loginLoader, b2Message} = b2State;
    return {loginLoader, b2Message}
};

export default connect(mapStateToProps, {
    processB2SecurityEvent
})(ResetPassword);
