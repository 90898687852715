import React from 'react';
import {getB2ChildrenComponents, resolveHeading} from 'util/B2Utils.js';
import CardBox from 'components/CardBox';

class B2Card extends React.Component {
    render() {
        let styleName='col-ls-'+this.props.uiDefinition.widthRatio;

        if (this.props.uiDefinition.styleName){
            styleName += ' ' + this.props.uiDefinition.styleName;
        }
        let style = null;
        if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }

        let headingStyle = null;
        if (this.props.uiDefinition.headerStyle){
            headingStyle = this.props.uiDefinition.headerStyle;
        }

        return (
            <CardBox styleName={styleName} cardInlineStyle={style} cardInlineStyle={style} cardHeadingInlineStyle={headingStyle} heading={resolveHeading(this.props.uiDefinition.title)}>
                <div className="row" noValidate autoComplete="off"  key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                     {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                </div>
            </CardBox>
        );
    }
}

export default B2Card;