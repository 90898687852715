import React from 'react';
import {connect} from 'react-redux'
//import {userSignOut} from 'actions/Auth';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    SIGNOUT_USER
} from 'constants/B2ActionTypes';

import {
    RESEND_ACTIVE_LINK
} from 'constants/SafeeActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';

class UserInfoPopup extends React.Component {
    userSignOut = () => {
        const b2SecEvent = {
            type: SIGNOUT_USER,
            eventDetails: {
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    resendActivationLink = () => {
        const b2SecEvent = {
            type: RESEND_ACTIVE_LINK,
            eventDetails: {
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    isAppAccessible(appName) {
        if (this.props.accessibleApps && this.props.accessibleApps.length>0){
            for (let i = 0; i < this.props.accessibleApps.length; i++) {
                if (this.props.accessibleApps[i].attributes.appName.value === appName){
                    return true;
                }
            }
        }

        return false;
    }

    getHomeURL(){
        let url = '/app/b2/home'
        if (this.props.accessibleApps && this.props.accessibleApps.length > 0){
            let accessibleHomeApp = this.props.accessibleApps.find((element) => {
                return element.attributes.appName &&
                    element.attributes.appName.value &&
                    element.attributes.appName.value !== null &&
                    typeof element.attributes.appName.value != 'undefined' &&
                    element.attributes.appName.value.toLowerCase() === 'home';
              });

            if (!accessibleHomeApp){
                if (this.props.defaultApp){
                    url =  '/app/b2/' + this.props.defaultApp.toLowerCase();
                }else if (this.props.dashboards && this.props.dashboards.length > 0 ){
                    let defaultDashboard = this.props.dashboards.find((element) => {
                        return element.attributes.elementType &&
                            element.attributes.elementType.value === 'APP' &&
                            element.attributes.menuType &&
                            element.attributes.menuType.value === 'DASHBOARD' &&
                            element.attributes.appName &&
                            element.attributes.appName.value &&
                            element.attributes.appName.value !== null &&
                            typeof element.attributes.appName.value != 'undefined' &&
                            element.attributes.appName.value.toLowerCase() !== 'home';
                      });
                    if (defaultDashboard){
                        url =  '/app/b2/' + defaultDashboard.attributes.appName.value.toLowerCase();
                    }else{
                        url =  '/app/b2/' + this.props.dashboards[0].attributes.appName.value.toLowerCase();
                    }
                }
            }
        }

        return url;
    }

    render() {
        return (
            <div>
                <div className="user-profile">
                    {/*<img className="user-avatar border-0 size-40 rounded-circle"
                       src="https://via.placeholder.com/150x150"
                       alt="User"/>*/}
                    <div className="user-detail ml-2">
                        <h5 className="user-name mb-0">{(this.props.user)?(
                                        ((this.props.user.attributes.firstName.value == null)?'': this.props.user.attributes.firstName.value+' ') +
                                         ((this.props.user.attributes.lastName.value == null)?'': this.props.user.attributes.lastName.value+' ')):
                                         ''
                                    }</h5>{/*<small>Administrator</small>*/}
                    </div>
                </div>
                <span className="jr-link dropdown-item text-muted">
                    <Link className="jr-list-link" to={this.getHomeURL()}> {/* TODO: add favourite apps*/}
                        <i className="zmdi zmdi-home zmdi-hc-fw mr-1"/>
                        <IntlMessages id="popup.home"/>
                    </Link>
                </span>

                {
                    this.isAppAccessible('Subscription') && <span className="jr-link dropdown-item text-muted">
                        <Link className="jr-list-link" to="/app/b2/subscription"> {/* TODO: add favourite apps*/}
                            <i className="zmdi zmdi-card-membership zmdi-hc-fw mr-1"/>
                            <IntlMessages id="safee.subscription"/>
                        </Link>
                    </span>
                }

                {
                    this.isAppAccessible('UserProfile') && <span className="jr-link dropdown-item text-muted">
                        <Link className="jr-list-link" to="/app/b2/userprofile"> {/* TODO: add favourite apps*/}
                            <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-1"/>
                            <IntlMessages id="popup.profile"/>
                        </Link>
                    </span>
                }

                {/*<span className="jr-link dropdown-item text-muted">
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>
                    <IntlMessages id="popup.setting"/>
                </span>*/}
                {
                    (this.props.user && this.props.user.attributes.status.value==='New') &&
                    <span className="jr-link dropdown-item text-muted" onClick={() => {
                            //console.log("Try to logoput");
                            this.resendActivationLink()
                        }}>
                        <i className="zmdi zmdi zmdi-link zmdi-hc-fw mr-1"/>
                        <IntlMessages id="safee.resendActivationLink"/>
                    </span>
                }
                <span className="jr-link dropdown-item text-muted" onClick={() => {
                        //console.log("Try to logoput");
                        this.userSignOut()
                    }}>
                    <i className="zmdi zmdi-run zmdi-hc-fw mr-1"/>
                    <IntlMessages id="popup.logout"/>
                </span>
            </div>
        );
    }
}

const mapStateToProps = ({b2State}) => {
  const {user, accessibleApps, dashboards, defaultApp} = b2State;
  return {user, accessibleApps, dashboards, defaultApp}
};

export default connect(mapStateToProps, {processB2SecurityEvent})(UserInfoPopup);