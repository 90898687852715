import React from 'react';
import connConfig from 'sagas/connection/config.js';

class B2Image extends React.Component {

    getValue(){
        if (!this.props.datasource.entityList || !this.props.datasource.entityList.selectedRecord){
            return "";
        }

        let value = null;

        if (this.props.rowDatasource && this.props.rowDatasource.selected !== "true"){
            value = this.props.rowDatasource.attributes["id"].value;
        }else{
            value = this.props.datasource.entityList.selectedRecord.attributes["id"].value;
        }

        return value;
    }

    render() {
        const value = this.getValue();

        const fullUrl = window.location.href;
        const arr = fullUrl.split("/");
        const protocol = (connConfig.protocol === "" || !connConfig.protocol)? (arr[0] + "//"):(connConfig.protocol+"://");
        let host = arr[2].split(":")[0];
        host = (connConfig.host === "" || !connConfig.host)?host:connConfig.host;
        let port = arr[2].split(":").length = 1?"":(":"+arr[2].split(":")[1]);
        port = (connConfig.port === "" || !connConfig.port)?port:":"+connConfig.port;
        const url = protocol + host + port + connConfig.contextRoot;

        const API_BASE_URL = url + (url.endsWith('/')?'rest/es/attachments/':'/rest/es/attachments/');

        if (value == null){
            return <React.Fragment/>;
        }else{
            return <img id={this.props.uiDefinition.id} key={this.props.uiDefinition.id} src={API_BASE_URL+value}/>;
        }
    }
}

export default B2Image;
