import React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import connConfig from "../../../sagas/connection/config";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, {Navigation, Pagination} from "swiper";


SwiperCore.use([Navigation, Pagination]);

/**
 * Represents a component for displaying images in a gallery or slideshow format.
 *
 * This component supports two modes:
 * - A slideshow mode using Swiper.
 * - A static gallery mode.
 *
 * @class B2ImageView
 * @extends {React.Component}
 * @property {Object} props.uidefinition - The properties object passed to the component.
 * @property {Object} state - The internal state of the component.
 * @UseCase
 * "B2ImageView": {
 *               "id": "recKeeperView_app_appBody_recKeeperView_app_appBody_B2DialogWeightInformationAttachments_B2DialogBody_B2GridCell_2_B2DialogBody_B2GridCell_1_B2ImageView",
 *               "onSlide": "true",    (optional, default: "false")
 *               "maxHeight": "300px", (optional, default: "300px")
 *               "spaceBetween": "10", (optional, default: "10")
 *               "slidesPerview": "1", (optional, default: "1")
 *               "imgSrc" : "[{url: \"https://source.unsplash.com/2ShvY8Lf6l0/800x599\", title: 1},{url: \"https://source.unsplash.com/Dm-qxdynoEc/800x799\",title: 2},{url: \"https://source.unsplash.com/qDkso9nvCg0/600x799\",title: 3},{url: \"https://source.unsplash.com/iecJiKe_RNg/600x799\",title: 4},{url: \"https://source.unsplash.com/epcsn8Ed8kY/600x799\",title: 5},{url: \"https://source.unsplash.com/NQSWvyVRIJk/800x599\",title: 6},{url: \"https://source.unsplash.com/zh7GEuORbUw/600x799\",title: 7},{url: \"https://source.unsplash.com/PpOHJezOalU/800x599\",title: 8},{url: \"https://source.unsplash.com/I1ASdgphUH4/800x599\",title: 9}]" (optional, default: What's inside the datasource)
 *             }
 */

class B2ImageView extends React.Component {

    /**
     * Logs information about the component's props to the console.
     * If there is no data in the datasource, logs "no data,!!!".
     *
     * @memberof B2ImageView
     */
    printConsole(){
        if (!this.props.datasource || !this.props.datasource.entityList)
            {
                console.log(this.props);
                console.log("no data!!!");
            }

        else
            console.log(this.props.datasource.entityList)
    }

    /**
     * Composes a URL for an image based on a given value.
     *
     * The URL is constructed using the connection configuration
     * and the application's context root.
     *
     * @param {string} value - The identifier for the image.
     * @returns {string} - The composed URL for the image.
     * @memberof B2ImageView
     */
    composeUrl(value){
        const fullUrl = window.location.href;
        const arr = fullUrl.split("/");
        const protocol = (connConfig.protocol === "" || !connConfig.protocol)? (arr[0] + "//"):(connConfig.protocol+"://");
        let host = arr[2].split(":")[0];
        host = (connConfig.host === "" || !connConfig.host)?host:connConfig.host;
        let port = arr[2].split(":").length = 1?"":(":"+arr[2].split(":")[1]);
        port = (connConfig.port === "" || !connConfig.port)?port:":"+connConfig.port;
        const url = protocol + host + port + connConfig.contextRoot;

        // Construct the base API URL for attachments
        const API_BASE_URL = url + (url.endsWith('/')?'rest/es/attachments/':'/rest/es/attachments/');

        return API_BASE_URL+value;
    }

    /**
     * Retrieves an array of images from the component's datasource.
     *
     * Filters the datasource for items where the "id" attribute is required,
     * then maps these items to an array of objects containing image URLs and titles.
     *
     * @returns {Array<{url: string, title: string}>} - An array of image objects.
     * @memberof B2ImageView
     */
    getImages(){
        if (!this.props.datasource || !this.props.datasource.entityList){
            return [];
        }

        let value = [];

        value = this.props.datasource.entityList.data
            .filter(item => item.attributes["id"].required === "true")
            .map(item => ({
            url: this.composeUrl(item.attributes["id"].value),
            title: item.attributes["description"].value,
        }));

        return value;
    }


    /**
     * Creates an instance of B2ImageView.
     *
     * Sets the initial state of the component.
     *
     * @param {Object} props - The properties passed to the component.
     * @memberof B2ImageView
     */
    constructor(props) {
        super(props);
        this.state = {
            galOpen: false,
            currentIndex: 0
        };
    }

    /**
     * Opens the gallery at a specific index.
     *
     * Sets the state to open the lightbox gallery and sets the index
     * of the currently displayed image.
     *
     * @param {number} index - The index of the image to open in the gallery.
     * @memberof B2ImageView
     */
    launchGallery = (index) => {
        this.setState({
            currentIndex: index,
            galOpen: true
        });
    }


    /**
     * Renders the component.
     *
     * Determines whether to render a slideshow or a static gallery based on the `onSlide` prop.
     * Also renders a lightbox gallery if `galOpen` is true.
     *
     * @returns {React.Element} - The rendered component.
     * @memberof B2ImageView
     */
    render() {
        // this.printConsole();

        const { galOpen, currentIndex } = this.state;

        var images = [];
        images = this.getImages();


        // Configure Swiper settings or normal settings
        var slidesPerView = this.props.uiDefinition.slidesPerview? this.props.uiDefinition.slidesPerview: "1";
        var spaceBetween = this.props.uiDefinition.spaceBetween? Number(this.props.uiDefinition.spaceBetween): 10;
        var unifiedHeight = this.props.uiDefinition.maxHeight? this.props.uiDefinition.maxHeight: "300px";
        var unifiedGap = this.props.uiDefinition.spaceBetween? this.props.uiDefinition.spaceBetween: "10";
        var isOnSlide = this.props.uiDefinition.onSlide? this.props.uiDefinition.onSlide: "false";
        images = this.props.uiDefinition.imgSrc?
            JSON.parse(this.props.uiDefinition.imgSrc.replace(/([{,]\s*)url:/g, '$1"url":')
                .replace(/title:/g, '"title":'))
                .map(item => ({ ...item, title: item.title.toString() }))
            : images;


        // Check if the images is empty and if the component should render as a slideshow
        if (images.length == 0){
            const alt = this.props.uiDefinition.noContentAlt;

            if (alt && ''!==alt){
                return (
                    <div key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                        <h3 align="center">{alt}</h3>
                    </div>
                );
            }else{
                return (
                    <div key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                        <h3 align="center">No Attachments</h3>
                    </div>
                );
            }
        }else if (isOnSlide === "true")
            return (
                <div key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                    <Swiper
                        spaceBetween={spaceBetween}
                        slidesPerView={slidesPerView}
                        navigation
                        pagination={{clickable: true}}
                        className="B2ImageViewSlides"
                    >
                        {images.map((image, idx) => (
                            <SwiperSlide key={image.url}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    <img
                                        src={image.url}
                                        alt={image.title}
                                        style={{
                                            height: unifiedHeight,
                                            objectFit: "cover",
                                        }}
                                        onClick={() => this.launchGallery(idx)}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {galOpen && (
                        <Lightbox
                            images={images.map((img) => ({url: img.url, title: `${img.title}`}))}
                            startIndex={currentIndex}
                            onClose={() => this.setState({galOpen: false})}
                        />
                    )}

                </div>
            );
        else
            return (
                // Otherwise, render a static gallery
                <div>
                    <div className="gal" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                        {images.map((image, idx) => (
                            <img key={image.url} src={image.url} alt={image.title}
                                 style={{height: unifiedHeight, margin: unifiedGap}}
                                 onClick={() => this.launchGallery(idx)}/>
                        ))}
                    </div>
                    {galOpen && <Lightbox startIndex={currentIndex} images={images}
                                          onClose={() => this.setState({galOpen: false})}/>}
                </div>
            );

    }
}


export default B2ImageView;