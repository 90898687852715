import React from 'react';
import B2ComponentsRegistry from 'components/B2ComponentRegistry';
import {store} from 'MainApp';

export const getB2ChildrenComponents = (props, componentUIDefinition, inheretedDatasource, inheretedBean) => {
    let childrenElements = [];
    if (Array.isArray(componentUIDefinition)){
        for(let i = 0 ; i < componentUIDefinition.length; i++) {
            childrenElements = childrenElements.concat(getB2ChildrenComponents(props, componentUIDefinition[i], inheretedDatasource, inheretedBean));
        }
    } else {
        for (var childElementName in componentUIDefinition) {
            if (Object.prototype.hasOwnProperty.call(componentUIDefinition, childElementName)) {
                if (Array.isArray(componentUIDefinition[childElementName])){
                    for(let i = 0 ; i < componentUIDefinition[childElementName].length; i++) {
                        if (Array.isArray(componentUIDefinition[childElementName][i])){
                            childrenElements = childrenElements.concat(getB2ChildrenComponents(props, componentUIDefinition[childElementName][i], inheretedDatasource, inheretedBean));
                        } else {

                            if(componentUIDefinition[childElementName][i].feature) {
                                let featureDatasource = null;
                                if (componentUIDefinition[childElementName][i].featureParentDatasource) {
                                  const parentDs = componentUIDefinition[childElementName][i].featureParentDatasource;
                                  if (props.beans[parentDs]) {
                                    const datasourceId = props.beans[parentDs].datasource;
                                    featureDatasource = props.datasources[datasourceId];
                                  }
                                } else {
                                    featureDatasource = getDatasource(componentUIDefinition[childElementName][i], props, inheretedDatasource);
                                }

                                if (!hasFeatureAccess(featureDatasource, componentUIDefinition[childElementName][i].feature)) {
                                    continue;
                                }
                            }

                            const TargetTag = B2ComponentsRegistry[childElementName];
                            if (TargetTag){
                                const {dataSource, bean, uiDefinition, parentUiDefinition, ...newProps} = props;
                                childrenElements.push(<TargetTag {...newProps}
                                                        key={componentUIDefinition[childElementName][i].id}
                                                        id={componentUIDefinition[childElementName][i].id}
                                                        parentUiDefinition={componentUIDefinition[childElementName]}
                                                        uiDefinition={componentUIDefinition[childElementName][i]}
                                                        datasource={getDatasource(componentUIDefinition[childElementName][i], props, inheretedDatasource)}
                                                        bean={getBean(componentUIDefinition[childElementName][i], props, inheretedBean)}
                                                        />);
                            }
                        }
                    }
                } else if (Object.prototype.toString.call(componentUIDefinition[childElementName]) === '[object Object]') {
                    const TargetTag = B2ComponentsRegistry[childElementName];

                    // TODO: Need refactor
                    if(componentUIDefinition[childElementName].feature) {
                        let featureDatasource = null;
                        if (componentUIDefinition[childElementName].featureParentDatasource) {
                            const parentDs = componentUIDefinition[childElementName].featureParentDatasource;
                            if (props.beans[parentDs]) {
                                const datasourceId = props.beans[parentDs].datasource;
                                featureDatasource = props.datasources[datasourceId];
                            }
                        } else {
                            featureDatasource = getDatasource(componentUIDefinition[childElementName], props, inheretedDatasource);
                        }

                        if (!hasFeatureAccess(featureDatasource, componentUIDefinition[childElementName].feature)) {
                            continue;
                        }
                    }

                    if (TargetTag){
                        const {datasource, bean, uiDefinition, parentUiDefinition, ...newProps} = props;

                        childrenElements.push(<TargetTag {...newProps}
                                                key={componentUIDefinition[childElementName].id}
                                                id={componentUIDefinition[childElementName].id}
                                                parentUiDefinition={componentUIDefinition}
                                                uiDefinition={componentUIDefinition[childElementName]}
                                                datasource={getDatasource(componentUIDefinition[childElementName], props, inheretedDatasource)}
                                                bean={getBean(componentUIDefinition[childElementName], props, inheretedBean)}
                                                />);
                    }
                } // else an attribute, no element to generate so we simply ignore
            }
        }
    }
    return childrenElements;
};

export const hasFeatureAccess = (datasource, feature) => {
    if (datasource && datasource.features){
        for(let i = 0 ; i < datasource.features.length; i++) {
            if (datasource.features[i] === feature) {
                return true;
            }
        }
    }
    return false;
}

export const getDatasource = (control, props, inheretedDatasource) => {
    if (props.beans[control.id]){
        const datasourceId = props.beans[control.id].datasource;
        return props.datasources[datasourceId];
    } else if (control.datasource){
        if (props.beans[control.datasource]){
            const datasourceId = props.beans[control.datasource].datasource;

            if (control.attribute && control.attribute.includes(".")){
                return props.datasources[datasourceId+"."+control.attribute.substring(0, control.attribute.lastIndexOf("."))];
            }else{
                return props.datasources[datasourceId];
            }
        }
    } else if (control.attribute && control.attribute.includes(".")){
        return props.datasources[inheretedDatasource.id+"."+control.attribute.substring(0, control.attribute.lastIndexOf("."))];
    }

    return inheretedDatasource;
};

export const getBean = (control, props, inheretedBean) => {
    if (props.beans[control.id]){
        return props.beans[control.id];
    }

    return inheretedBean;
};

export const getAppName = (app, path) => {
    if (app){
        return app.appName;
    }else{
        return getAppNameFromPath(path);
    }
};

export const getAppNameFromPath = (path) => {
    if (path.toUpperCase()  === '/APP/B2/' ||
        path.toUpperCase()  === '/APP/B2' ||
        path.toUpperCase()  === '/APP/' ||
        path.toUpperCase()  === '/APP' ||
        path  === '/' ||
        path === ''
        ){
        return 'home';
    }else if (path.toUpperCase().startsWith('/APP/B2/')) {
        const appName = path.substr(8, path.length);
        return appName;
    }else{
        return path;
    }
};

export const isAnyDatasourceModified = (datasources) => {
    for (var datasource in datasources) {
        if (Object.prototype.hasOwnProperty.call(datasources, datasource)) {
            if (datasource.modified){
                return true;
            }
        }
    }
    return false;
};

export const getOriginalSelectedRecord = (datasourceId) => {
    let selectedRecordId = store.getState().b2State.datasources[datasourceId].entityList.selectedRecord.attributes.id.value;
    for(let i = 0 ; i < store.getState().b2State.datasources[datasourceId].entityList.data.length; i++) {
        if (store.getState().b2State.datasources[datasourceId].entityList.data[i].attributes.id.value === selectedRecordId){
            return store.getState().b2State.datasources[datasourceId].entityList.data[i];
        }
    }
}

export const getOriginalRecordById = (datasourceId, entityId) => {
    for(let i = 0 ; i < store.getState().b2State.datasources[datasourceId].entityList.data.length; i++) {
        if (store.getState().b2State.datasources[datasourceId].entityList.data[i].attributes.id.value === entityId){
            return store.getState().b2State.datasources[datasourceId].entityList.data[i];
        }
    }
}

export const resolveHeading = (heading) => {
    if (!heading){
        return heading;
    }
    const pattern = /[^{}]*(?=\})/g;
    const headingPlaceHolder = heading.match(pattern);

    if (!headingPlaceHolder || !Array.isArray(headingPlaceHolder)){
        return heading;
    }

    if (headingPlaceHolder[0].includes(".")){
        const datasourceId = headingPlaceHolder[0].substring(0, headingPlaceHolder[0].lastIndexOf("."));
        const attribute = headingPlaceHolder[0].substring(headingPlaceHolder[0].lastIndexOf(".")+1, headingPlaceHolder[0].length);
        let datasource = store.getState().b2State.datasources[datasourceId];
        if (datasource){
            if (datasource.entityList.selectedRecord){
                if (datasource.entityList.selectedRecord.attributes[attribute].value === null){
                    return heading.replace("{"+headingPlaceHolder[0]+"}", "");
                }else{
                    return heading.replace("{"+headingPlaceHolder[0]+"}", datasource.entityList.selectedRecord.attributes[attribute].value);
                }
            }else{
                return heading.replace("{"+headingPlaceHolder[0]+"}", "");
            }
        }else {
            const bean = store.getState().b2State.beans[datasourceId];
            datasource = store.getState().b2State.datasources[bean.datasource];
            if (datasource.entityList.selectedRecord){
                if (datasource.entityList.selectedRecord.attributes[attribute].value === null){
                    return heading.replace("{"+headingPlaceHolder[0]+"}", "");
                }else{
                    return heading.replace("{"+headingPlaceHolder[0]+"}", datasource.entityList.selectedRecord.attributes[attribute].value);
                }
            }else{
                return heading.replace("{"+headingPlaceHolder[0]+"}", "");
            }
        }
    }else{
        return heading;
    }
}

export const setCookie = (name,value,minutes) => {
    var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const eraseCookie = (name) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}