import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import SearchBox from 'components/SearchBox';
import {ON_APP_SEARCH_FIELD_VALUE_CHANGED, ON_APP_SEARCH_PERFORMED} from 'constants/B2ActionTypes';

class B2ToolBar extends React.Component {
    updateSearch(evt) {
        this.props.updateRecordsSearch(evt.target.value);
        this.props.onSearchRecords(evt.target.value)
    }

    searchFieldChanged = event => {
        event.stopPropagation();

        const b2Event = {
            type: ON_APP_SEARCH_FIELD_VALUE_CHANGED,
            eventDetails: {
                searchValue: event.target.value
            }
        }

        this.props.processB2Event(b2Event);
    };

    doSearch = event => {
        if(event.charCode === 13){
            event.preventDefault(); // Ensure it is only this code that runs

            const b2Event = {
                type: ON_APP_SEARCH_PERFORMED,
                eventDetails: {
                    searchValue: event.target.value
                }
            }

            this.props.processB2Event(b2Event);
        }
    }

    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            searchBox: false
        };
    }

    render() {
        return (<React.Fragment key={this.props.uiDefinition.id}>
                    <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
                        <div className="form-group">
                            <input className="form-control border-0" type="search" placeholder={this.props.uiDefinition.placeholder}
                                onChange={(e) => this.searchFieldChanged(e)}
                                onKeyPress={this.doSearch}
                                value={this.props.searchValue}/>
                            <button className="search-icon"><i className="zmdi zmdi-search zmdi-hc-lg"/></button>
                        </div>

                    </div>

                    <div className="d-inline-block d-sm-none">
                        <Dropdown
                            className="quick-menu nav-searchbox"
                            isOpen={this.state.searchBox}
                            toggle={this.onSearchBoxSelect.bind(this)}>

                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">
                                <IconButton className="icon-btn">
                                <i className="zmdi zmdi-search zmdi-hc-fw text-grey"/>
                                </IconButton>
                            </DropdownToggle>

                            <DropdownMenu className="p-0">
                                <SearchBox styleName="search-dropdown" placeholder=""
                                    onChange={this.updateSearch.bind(this)}
                                    value={this.props.searchRecords}/>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </React.Fragment>
            );
    }
}

export default B2ToolBar;
