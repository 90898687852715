import {all} from 'redux-saga/effects';
import b2Sagas from './B2App';
import b2DownloadAttachment from './B2DownloadAttachment';
import safeeDownloadEWD from './Safee/SafeeDownloadEWD';
import safeeDownloadForms from './Safee/SafeeDownloadForms';

export default function* rootSaga(getState) {
    yield all([
        b2Sagas(),
        b2DownloadAttachment(),
        safeeDownloadEWD(),
        safeeDownloadForms()
    ]);
}
