export default [
    {
        'title': 'All in current page',
    },
    {
        'title': 'None in current page',
    },
    {
        'title': 'None',
    },
    {
        'title': 'Bookmarked',
    },
    {
        'title': 'Unbookmarked',
    }
];
