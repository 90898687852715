import React from 'react';
import Button from '@material-ui/core/Button';

class B2Button extends React.Component {
    sendEvent = () => {
        const b2Event = {
            type: this.props.uiDefinition.event,
            eventDetails: {
                beanId: this.props.bean.id,
                datasourceId: this.props.datasource.id
            }
        }

        this.props.processB2Event(b2Event);
    }

    render() {
        let style = null;
        if (this.props.style){
            style = this.props.style;
        } else if (this.props.uiDefinition.style){
            style = this.props.uiDefinition.style;
        }else{
            style = { "margin" : "12px 7px"}
        }

        // TODO: handle button icon
        return (
            <Button variant="contained" color="primary" className="jr-btn text-white"
                    onClick={() => {
                        this.sendEvent();
                    }} style={style}>
                    {this.props.uiDefinition.label}
            </Button>
        );
    }
}

export default B2Button;