import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';

class B2DialogButtonGroup extends React.Component {
    render() {
        return  <div className="modal-box-footer flex-row"
                       id={this.props.uiDefinition.id} key={this.props.uiDefinition.id}
                       style={{textAlign: ((!this.props.uiDefinition.align)?'': this.props.uiDefinition.align)}}>
                    {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                </div>
    }
}

export default B2DialogButtonGroup;