import React, {Component} from 'react';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
//import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';

import MainApp from 'app/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from  './ResetPassword'
import Activate from  './Activate'
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';

import {
    SET_INIT_URL
} from 'constants/B2ActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';


const RestrictedRoute = ({component: Component, userToken, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      userToken
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

//    setInitUrl = (initURL) => {
//        const b2SecEvent = {
//            type: SET_INIT_URL,
//            eventDetails: {
//                initURL: initURL
//            }
//        }
//
//        this.props.processB2SecurityEvent(b2SecEvent);
//    }

    componentWillMount() {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
//        if (this.props.initURL === '') {
//            this.setInitUrl(this.props.history.location.pathname);
//        }
    }

    render() {
        const {match, location, locale, isDirectionRTL} = this.props;

//        const params = new Proxy(new URLSearchParams(window.location.search), {
//          get: (searchParams, prop) => searchParams.get(prop),
//        });

//        let tokenQueryParam = params['x-auth-token'];
//console.log("XXX = " + tokenQueryParam);
//console.log(location.pathname);
//        if (tokenQueryParam && ''!==tokenQueryParam){
//            //let queryString = window.location.search;
//
//            let {userToken} = this.props;
//            if (!userToken){
//                return ( <Redirect to={'/signin?token='+tokenQueryParam}/> );
//            }
//
//        }

        let {userToken} = this.props;

        if (
            !userToken &&
            location.pathname.toLowerCase() !== '/signin' &&
            !location.pathname.toLowerCase().startsWith('/signin?') &&
            location.pathname.toLowerCase() !== '/forgotpassword' &&
            !location.pathname.toLowerCase().startsWith('/forgotpassword?') &&
            location.pathname.toLowerCase() !== '/resetpassword' &&
            !location.pathname.toLowerCase().startsWith('/resetpassword?') &&
            location.pathname.toLowerCase() !== '/signup' &&
            !location.pathname.toLowerCase().startsWith('/signup?') &&
            location.pathname.toLowerCase() !== '/activate' &&
            !location.pathname.toLowerCase().startsWith('/activate?')
        ) {
            return ( <Redirect to={'/signin'}/> );
        }else if (
            location.pathname === '' ||
            location.pathname.startsWith('?') ||
            location.pathname === '/' ||
            location.pathname.startsWith('/?') ||
            location.pathname.toLowerCase() === '/app/b2/' ||
            location.pathname.toLowerCase().startsWith('/app/b2/?') ||
            location.pathname.toLowerCase() === '/app/b2' ||
            location.pathname.toLowerCase().startsWith('/app/b2?') ||
            location.pathname.toLowerCase() === '/app' ||
            location.pathname.toLowerCase().startsWith('/app?') ||
            location.pathname.toLowerCase() === '/app/' ||
            location.pathname.toLowerCase().startsWith('/app/?')
        ) {
            let url = '/';

            if (this.props.defaultApp){
                url =  '/app/b2/' + this.props.defaultApp.toLowerCase();
            }else if (this.props.dashboards && this.props.dashboards.length > 0 ){

                let defaultDashboard = this.props.dashboards.find((element) => {
                    return element.attributes.elementType &&
                        element.attributes.elementType.value === 'APP' &&
                        element.attributes.menuType &&
                        element.attributes.menuType.value === 'DASHBOARD' &&
                        element.attributes.appName &&
                        element.attributes.appName.value &&
                        element.attributes.appName.value !== null &&
                        typeof element.attributes.appName.value !== 'undefined' &&
                        element.attributes.appName.value.toLowerCase() !== 'home';
                  });
                if (defaultDashboard){
                    url =  '/app/b2/' + defaultDashboard.attributes.appName.value.toLowerCase();
                }else{
                    url = '/app/b2/home';
                }
            }else{
                url = '/app/b2/home';
            }

            return ( <Redirect to={url}/> );
        }else if (
            location.pathname.toLowerCase() === '/app/b2/home' ||
            location.pathname.toLowerCase() === '/app/b2/home/' ||
            location.pathname.toLowerCase().startsWith('/app/b2/home?') ||
            location.pathname.toLowerCase().startsWith('/app/b2/home/?')
        ) {
            if (this.props.accessibleApps && this.props.accessibleApps.length > 0){
                let accessibleHomeApp = this.props.accessibleApps.find((element) => {
                    return element.attributes.appName &&
                        element.attributes.appName.value &&
                        element.attributes.appName.value !== null &&
                        typeof element.attributes.appName.value != 'undefined' &&
                        element.attributes.appName.value.toLowerCase() === 'home';
                  });

                if (!accessibleHomeApp){

                    let homeDashboard = this.props.dashboards.find((element) => {

                        return element.attributes.elementType &&
                            element.attributes.elementType.value === 'APP' &&
                            element.attributes.menuType &&
                            element.attributes.menuType.value === 'DASHBOARD' &&
                            element.attributes.appName &&
                            element.attributes.appName.value &&
                            element.attributes.appName.value !== null &&
                            typeof element.attributes.appName.value !== 'undefined' &&
                            element.attributes.appName.value.toLowerCase() === 'home'
                      });

                    if (!homeDashboard){

                        let url = '';
                        if (this.props.defaultApp){
                            url =  '/app/b2/' + this.props.defaultApp.toLowerCase();
                        }else if (this.props.dashboards && this.props.dashboards.length > 0 ){
                            let defaultDashboard = this.props.dashboards.find((element) => {

                                return element.attributes.elementType &&
                                    element.attributes.elementType.value === 'APP' &&
                                    element.attributes.menuType &&
                                    element.attributes.menuType.value === 'DASHBOARD' &&
                                    element.attributes.appName &&
                                    element.attributes.appName.value &&
                                    element.attributes.appName.value !== null &&
                                    typeof element.attributes.appName.value !== 'undefined' &&
                                    element.attributes.appName.value.toLowerCase() !== 'home'
                              });
                            if (defaultDashboard){
                                url =  '/app/b2/' + defaultDashboard.attributes.appName.value.toLowerCase();
                            }else{
                                url =  '/app/b2/' + this.props.dashboards[0].attributes.appName.value.toLowerCase();
                            }
                        }
                        return ( <Redirect to={url}/> );
                    }
                }
            }
        }

        const applyTheme = createTheme(defaultTheme);

        if (isDirectionRTL) {
            applyTheme.direction = 'rtl';
            document.body.classList.add('rtl')
        } else {
            document.body.classList.remove('rtl');
            applyTheme.direction = 'ltr';
        }

        const currentAppLocale = AppLocale[locale.locale];

        return (
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}>
                        <RTL>
                            <div className="app-main">
                                <Switch>
                                    <RestrictedRoute path={`${match.url}app/b2`} userToken={userToken} component={MainApp}/>
                                    <Route path='/signin' component={SignIn}/>
                                    <Route path='/signup' component={SignUp}/>
                                    <Route path='/forgotpassword' component={ForgotPassword}/>
                                    <Route path='/activate' component={Activate}/>
                                    <Route path='/resetPassword' component={ResetPassword}/>
                                    <Route component={asyncComponent(() => import('components/Error404'))}/>
                                </Switch>
                            </div>
                        </RTL>
                    </IntlProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = ({settings, b2State}) => {
  const {sideNavColor, locale, isDirectionRTL} = settings;
  const {userToken, dashboards, accessibleApps, defaultApp} = b2State;
  return {sideNavColor, locale, isDirectionRTL, userToken, dashboards, accessibleApps, defaultApp}
};

export default connect(mapStateToProps, {processB2SecurityEvent})(App);

