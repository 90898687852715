import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
//import Tour from '../components/Tour/index';
import {processB2Event} from 'actions/B2App';
import B2AppWithRedux from './routes/b2/app'

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';

import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';

class App extends React.Component {
    render() {
        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;

        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        }
        else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        /*window.addEventListener("beforeunload", function (e) {

          var confirmationMessage = "\o/";
          e.returnValue = confirmationMessage;
          return confirmationMessage;

        });*/

        return (
            <div className={`app-container ${drawerStyle}`}>
                {/*<Tour/>*/}

                {(this.props.dashboards && this.props.systemMenu) && <Sidebar/>}
                <div className="app-main-container">
                    {(this.props.dashboards && this.props.systemMenu) && <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName="app-top-header"/>}
                        <Header/>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                        <TopNav/>}
                    </div>}

                    <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                            <Switch>
                                <Route strict path={`${match.url}`} key={'B2APP_Container'} render={(_props) => <B2AppWithRedux />}/>
                                <Route component={asyncComponent(() => import('components/Error404'))}/>
                            </Switch>
                        </div>
                        <Footer/>
                    </main>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({b2State, settings}) => {
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;
    const {
        app,
        systemMenu,
        dashboards
    } = b2State;

    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        app,
        systemMenu,
        dashboards
    }
};

export default withRouter(connect(mapStateToProps, {processB2Event})(App));
