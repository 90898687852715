import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';

class B2DialogBody extends React.Component {
    render() {
        return  <div className="modal-box-content" id={this.props.uiDefinition.id} key={this.props.uiDefinition.id}>
                    <div className="row no-gutters">
                        {getB2ChildrenComponents(this.props, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                    </div>
                </div>
    }
}

export default B2DialogBody;