import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import queryString from 'query-string';
import {useLocation} from "react-router-dom";

import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignUp,
    userTwitterSignIn
} from 'actions/B2Auth';

import {
    SIGNUP_USER,
    CLOSE_B2_MESSAGE
} from 'constants/B2ActionTypes';

import {
    processB2SecurityEvent
} from 'actions/B2App';

class SignUp extends React.Component {
    constructor() {
        super();

        this.state = {
            firstName:'',
            lastName:'',
            mobileNumber: '',
            signupAs: 'recordkeeper',
            email: queryString.parse(window.location.search)['email']?queryString.parse(window.location.search)['email']:'',
            password: ''
        }
    }

    userSignUp = () => {
        const b2SecEvent = {
            type: SIGNUP_USER,
            eventDetails: {
                loginName: this.state.email,
                userId: this.state.email,
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                password: this.state.password,
                mobileNumber: this.state.mobileNumber,
                signupAs: this.state.signupAs
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    closeB2Message = () => {
        const b2SecEvent = {
            type: CLOSE_B2_MESSAGE,
            eventDetails: {}
        }
        this.props.processB2SecurityEvent(b2SecEvent);
    }

    componentDidUpdate() {
        if (this.props.b2Message) {
            setTimeout(() => {
                this.closeB2Message();
            }, 100);
        }
        if (this.props.userToken !== null) {
            this.props.history.push('/');
        }
    }

    render() {
        const {
            firstName,
            lastName,
            mobileNumber,
            signupAs,
            email,
            password
        } = this.state;

        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Safee">
                            <img src={require("assets/images/safee_logo_square.png")} /*style={{"width":"200px", "height":"145px"}}*/ alt="Safee" title="Safee"/>
                        </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header">
                            <h1>Sign Up</h1>
                        </div>

                        <div className="mb-4">
                            <h2><IntlMessages id="appModule.createAccount"/></h2>
                        </div>

                        <div className="app-login-form">
                            <form method="post" action="/">
                                <TextField
                                    type="text"
                                    label="First Name"
                                    onChange={(event) => this.setState({firstName: event.target.value})}
                                    fullWidth
                                    defaultValue={firstName}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="text"
                                    label="Last Name"
                                    onChange={(event) => this.setState({lastName: event.target.value})}
                                    fullWidth
                                    defaultValue={lastName}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                {/* <TextField
                                  type="text"
                                  label="Name"
                                  onChange={(event) => this.setState({name: event.target.value})}
                                  fullWidth
                                  defaultValue={name}
                                  margin="normal"
                                  className="mt-0 mb-2"
                                /> */}

                                <TextField
                                    type="email"
                                    onChange={(event) => this.setState({email: event.target.value})}
                                    label={<IntlMessages id="appModule.email"/>}
                                    fullWidth
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({password: event.target.value})}
                                    label={<IntlMessages id="appModule.password"/>}
                                    fullWidth
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="tel"
                                    onChange={(event) => this.setState({mobileNumber: event.target.value})}
                                    label={<IntlMessages id="safee.appModule.mobileNumber"/>}
                                    fullWidth
                                    defaultValue={mobileNumber}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <FormControl
                                    className="mt-0 mb-4"
                                    margin="normal"
                                    key="signupas"
                                    disabled={false}

                                    fullWidth
                                    autoComplete='new-password'
                                    >
                                    <InputLabel>Sign Up As</InputLabel>
                                    <Select
                                        value={signupAs}
                                        onChange={(event, option) => {console.log(option);this.setState({signupAs: option.props.value});}}
                                        label={'Sign Up As'}
                                        inputProps={{ readOnly: false }}
                                    >
                                        <MenuItem lookupid='Signup_Record_Keeper' value='recordkeeper' key='Signup_Record_Keeper'>Record Keeper</MenuItem>
                                        {
                                            // <MenuItem lookupid='Signup_Driver' value='driver' key='Signup_Driver'>Driver</MenuItem>
                                        }
                                    </Select>
                                </FormControl>

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button variant="contained" onClick={() => {
                                            this.userSignUp();
                                        }} color="primary">
                                        <IntlMessages id="appModule.regsiter"/>
                                    </Button>
                                    <Link to="/signin">
                                        <IntlMessages id="signUp.alreadyMember"/>
                                    </Link>
                                </div>
                                <div className="app-social-block my-1 my-sm-3">
                                    <IntlMessages id="signIn.connectWith"/>
                                    <ul className="social-link">
                                        <li>
                                            <Link to={{ pathname: "https://www.facebook.com/profile.php?id=100065027493567" }} target="_blank">
                                                <IconButton className="icon"
                                                    onClick={() => {
                                                        window.location.path = 'https://www.facebook.com/profile.php?id=100065027493567';
                                                    }}>
                                                    <i className="zmdi zmdi-facebook"/>
                                                </IconButton>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to={{ pathname: "https://www.linkedin.com/company/safeeelogbook/" }} target="_blank">
                                                <IconButton className="icon"
                                                    onClick={() => {
                                                        window.location.path = 'https://www.linkedin.com/company/safeeelogbook/';
                                                    }}>
                                                    <i className="zmdi zmdi-linkedin"/>
                                                </IconButton>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to={{ pathname: "https://twitter.com/SafeeAU" }} target="_blank">
                                                <IconButton className="icon"
                                                    onClick={() => {
                                                        window.location.path = 'https://twitter.com/SafeeAU';
                                                    }}>

                                                    <i className="zmdi zmdi-twitter"/>
                                                </IconButton>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to={{ pathname: "https://www.youtube.com/@SafeeELogbook/playlists" }} target="_blank">
                                                <IconButton className="icon"
                                                    onClick={() => {
                                                        window.location.path = 'https://www.youtube.com/@SafeeELogbook/playlists';
                                                    }}>
                                                    <i className="zmdi zmdi-youtube-play"/>
                                                </IconButton>
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.props.loginLoader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {this.props.b2Message && NotificationManager.error(this.props.b2Message.message)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = ({b2State}) => {
    const {loginLoader, b2Message, userToken} = b2State;
    return {loginLoader, b2Message, userToken}
};

export default connect(mapStateToProps, {
    processB2SecurityEvent
})(SignUp);
